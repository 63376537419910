import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { forkJoin, Observable, of, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { NoteModel } from "./note.model";
import { LookupModel } from "./lookup.model";
import { SharedService } from "../shared.service";
import { NotePermissions } from "../enums/permission/notePermissions";
import { LayoutUtilsService, MessageType } from "../../../core/_base/crud";
import { NoteCategories } from "../enums/notes.enum";
import { EntityType } from "../enums/entity/entityType";
import { isEmpty } from "lodash";
import { BaseSettingsModel } from "../../pages/settings/base-settings.model";
import { InputTypesEnum } from "../../pages/settings/components/Economic-jobs-dialog/input-type-enum";
import { ContractsService } from "../../pages/contracts";
import { catchError } from "rxjs/internal/operators/catchError";
import { ICustomField } from "../../pages/settings/components/template-custom-fields-settings-dialog/template-custom-fields-settings.interface";
import { IEntityCustomField } from "./custom-field.interface";
import { MatSelectChange } from "@angular/material/select";
import { OffersService } from "../../pages/offers";
import { finalize } from "rxjs/operators";

@Component({
  selector: "kt-notes-and-fields",
  templateUrl: "./note-and-fields-dialog.component.html",
  styleUrls: ["./note-and-fields-dialog.component.scss"],
})
export class NoteAndFieldsDialogComponent implements OnInit {
  public note: NoteModel;
  public categories: Array<LookupModel> = [];
  public customers: Array<any>;
  private componentSubscriptions: Subscription[] = [];
  protected canCreateExternalNote: boolean;
  protected canCreateNote: boolean;
  public noteLoading: boolean = false;
  public fieldsLoading: boolean = false;
  is_customer: boolean = false;
  is_task: boolean = false;
  hasNotes: boolean = this.data.hasNotes ?? true;
  customFieldsForm: FormGroup = this.formBuilder.group({
    fields: this.formBuilder.array([]),
  });
  public noteForm: FormGroup = this.formBuilder.group({
    category_id: ["", Validators.required],
    text: ["", Validators.required],
    customer_id: [null],
    entity_type: [""],
  });
  customFieldsSettings: BaseSettingsModel<ICustomField[]> = this.data.customFieldsSettings;
  inputTypes = InputTypesEnum;
  pdfNotesPermissions: boolean = false;
  canCreatePdfNotes: boolean = false;
  isNotesVisible: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private productsService: SharedService,
    public dialogRef: MatDialogRef<NoteAndFieldsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translate: TranslateService,
    private layoutUtilsService: LayoutUtilsService,
    private sharedService: SharedService,
    private contractsService: ContractsService,
    private offersService: OffersService
  ) {
    const permissionsNames = Object.values(this.data.permissions).map((permission: any) => {
      return permission.name;
    });
    this.canCreateExternalNote = permissionsNames.includes(NotePermissions.CREATE_EXTERNAL_NOTES);
    this.canCreateNote = permissionsNames.includes(NotePermissions.CREATE_NOTES);
    this.canCreatePdfNotes = permissionsNames.includes(NotePermissions.CREATE_PDF_NOTES);
    const canViewPdfNotes = permissionsNames.includes(NotePermissions.VIEW_PDF_NOTES);

    this.pdfNotesPermissions = this.canCreatePdfNotes && canViewPdfNotes;
    this.isNotesVisible = this.data?.is_signing_task ? this.pdfNotesPermissions : this.hasNotes;
    this.is_task = !!this.data?.is_task;
    this.noteForm.controls.text.setValidators(this.is_task ? Validators.nullValidator : Validators.required);
    this.noteForm.controls.text.updateValueAndValidity();
  }

  ngOnInit(): void {
    this.customers = [...new Set(this.data.customers)];
    this.loadCategories();
    this.loadCustomFields();
  }

  getEntityCustomFields() {
    if (this.data.entity_type === EntityType.CONTRACT) {
      this.contractsService
        .getContractCustomFields(this.data.entity_id)
        .pipe(
          finalize(() => {
            this.fieldsLoading = false;
          })
        )
        .subscribe((res) => {
          const enityCustomFields = res.data;
          enityCustomFields?.forEach((field) => {
            this.addCustomField(field);
          });
        });
    } else if (this.data.entity_type === EntityType.OFFER) {
      this.offersService
        .getOfferCustomFields(this.data.entity_id)
        .pipe(
          finalize(() => {
            this.fieldsLoading = false;
          })
        )
        .subscribe((res) => {
          const enityCustomFields = res.data;
          enityCustomFields?.forEach((field) => {
            this.addCustomField(field);
          });
        });
    }
  }

  getCustomFieldSettings() {
    if (this.customFieldsSettings.is_active) {
      this.getEntityCustomFields();
      return;
    }
    this.fieldsLoading = false;
  }

  get customFieldsFormArray(): FormArray {
    return this.customFieldsForm.get("fields") as FormArray;
  }

  loadCustomFields() {
    if (!this.is_task) return;
    this.fieldsLoading = true;
    this.getCustomFieldSettings();
  }

  addCustomField(customFieldValue: IEntityCustomField | null = null) {
    if (!customFieldValue) {
      customFieldValue = {
        ...this.remainingUnusedSettings[0],
        value: this.remainingUnusedSettings[0].default,
      };
    }
    const customFieldGroup = this.formBuilder.group({
      template_key: [""],
      name: [""],
      type: [InputTypesEnum.STRING],
      value: [null, Validators.required],
    });
    customFieldGroup.patchValue(customFieldValue);
    this.customFieldsFormArray.push(customFieldGroup);
  }

  onFieldSelect(event: MatSelectChange, index: number) {
    const selectedField: ICustomField = this.customFieldsSettings.value.find(
      (field) => field.template_key === event.value
    );
    const fieldControl = this.customFieldsFormArray.at(index) as FormGroup;
    fieldControl.patchValue(selectedField);
    fieldControl.controls.value.setValue(selectedField.default);
  }

  removeCustomField(index) {
    this.customFieldsFormArray.removeAt(index);
  }

  public loadCategories() {
    if (!this.hasNotes) {
      return;
    }
    this.noteLoading = true;
    this.productsService.getNoteCategories().subscribe(
      (res) => {
        this.loadNote();

        this.categories = res.data;
        this.noteLoading = false;

        const permissionsMap = new Map([
          [NoteCategories.PDF_NOTE, this.canCreatePdfNotes],
          [NoteCategories.EXTERNAL_NOTE, this.canCreateExternalNote],
        ]);

        this.categories = !this.canCreateNote
          ? res.data.filter((category) => permissionsMap.get(category.code))
          : res.data;
      },
      (err) => {
        const message = err?.error?.message;
        this.layoutUtilsService.showActionNotification(message, MessageType.Delete, 10000, true, false);
        this.noteLoading = false;
      }
    );
  }

  public loadNote() {
    if (!this.is_task) {
      this.loadEntityNote();
    } else if (this.pdfNotesPermissions) {
      this.loadLatestNoteOnPdf();
    }
  }

  get remainingUnusedSettings() {
    const usedTemplateKeys = this.customFieldsFormArray.controls.map((field: FormGroup) => {
      return field.controls.template_key.value;
    });
    return this.customFieldsSettings?.value.filter((field) => !usedTemplateKeys.includes(field.template_key)) ?? [];
  }

  get emptyCustomFields() {
    return this.customFieldsFormArray.controls.filter((field: FormGroup) => !field.controls.template_key.value);
  }

  private loadEntityNote() {
    if (this.data.entity) {
      this.note = this.data.entity;
      switch (this.data.entity_type) {
        case "offer":
          this.note.offer_id = this.data.entity_id;
          delete this.note.contract_id;
          delete this.note.customer_id;
          break;

        case "contract":
          this.note.contract_id = this.data.entity_id;
          delete this.note.offer_id;
          break;

        case "customer":
          this.note.customer_id = parseInt(this.data.entity_id);
          this.is_customer = true;
          delete this.note.contract_id;
          delete this.note.offer_id;
          break;

        default:
          this.note.contract_id = this.data.entity_id;
          break;
      }
    } else this.note = new NoteModel().clear();
    this.note.text.replaceAll("<br />", "\n");
    this.noteForm.controls.text.setValue(this.note.text);
    this.noteForm.controls.customer_id.setValue(this.note.customer_id ?? 0);
    this.noteForm.controls.entity_type.setValue(this.data.entity_type);
    this.noteForm.controls.category_id.setValue(this.note.category_id);
  }

  private loadLatestNoteOnPdf() {
    this.noteLoading = true;
    this.note = new NoteModel().clear();
    if (this.data.entity_type !== EntityType.CONTRACT) return;

    const noteSubscription = this.sharedService.getLatestNoteOnPdf(this.data.entity_id).subscribe(
      (res) => {
        if (res.data.note) {
          this.note.setAttributes(res.data.note);
          this.noteForm.controls.text.setValue(this.note.text);
        }
        this.noteForm.controls.entity_type.setValue(this.data.entity_type);
        this.note.contract_id = this.data.entity_id;
        delete this.note.offer_id;
        delete this.note.customer_id;
        const pdfCategory = Object.values(this.categories).find(
          (category) => category.code === NoteCategories.PDF_NOTE
        );
        this.noteForm.controls.category_id.setValue(pdfCategory.id);
        this.noteLoading = false;
      },
      (err) => {
        const message = err?.error?.message ?? this.translate.instant("GENERAL.SOMETHING_WENT_WRONG");
        this.layoutUtilsService.showActionNotification(message, MessageType.Delete, 10000, true, false);
        this.noteLoading = false;
      }
    );
    this.componentSubscriptions.push(noteSubscription);
  }

  public onSubmit() {
    const fieldsRequest = this.submitCustomFields();
    const notesRequest = this.submitNote();

    const requestsSubscription = forkJoin([fieldsRequest, notesRequest]).subscribe({
      next: ([fieldRes, noteRes]) => {
        this.noteLoading = false;
        this.fieldsLoading = false;
        if (fieldRes && noteRes) {
          this.dialogRef.close(noteRes);
        }
      },
    });

    this.componentSubscriptions.push(requestsSubscription);
  }

  private submitCustomFields(): Observable<any> {
    if (!this.is_task || !this.customFieldsSettings.is_active) return of(true);

    if (this.customFieldsForm.invalid) {
      this.customFieldsForm.markAllAsTouched();
      return of(false);
    }

    if (this.data.entity_type === EntityType.CONTRACT) {
      this.fieldsLoading = true;
      return this.contractsService.insertContractCustomFields(this.data.entity_id, this.customFieldsForm.value).pipe(
        catchError((err) => {
          const message = err?.error?.message ?? this.translate.instant("GENERAL.SOMETHING_WENT_WRONG");
          this.layoutUtilsService.showActionNotification(message, MessageType.Delete, 10000, true, false);
          this.fieldsLoading = false;
          return of(false);
        })
      );
    } else if (this.data.entity_type === EntityType.OFFER) {
      this.fieldsLoading = true;
      return this.offersService.insertOfferCustomFields(this.data.entity_id, this.customFieldsForm.value).pipe(
        catchError((err) => {
          const message = err?.error?.message ?? this.translate.instant("GENERAL.SOMETHING_WENT_WRONG");
          this.layoutUtilsService.showActionNotification(message, MessageType.Delete, 10000, true, false);
          this.fieldsLoading = false;
          return of(false);
        })
      );
    }
  }

  private submitNote(): Observable<any> {
    if (!this.hasNotes || !this.isNotesVisible) return of(true);
    this.note.text = this.noteForm.controls.text.value;
    this.noteForm.controls.customer_id.value
      ? (this.note.customer_id = this.noteForm.controls.customer_id.value)
      : delete this.note.customer_id;
    this.noteForm.controls.customer_id.value === 0 ? delete this.note.customer_id : "";
    this.note.category_id = this.noteForm.controls.category_id.value;
    if (this.data.entity_type === "offer" || this.data.entity_type === "customer") delete this.note.contract_id;

    if (this.is_task && isEmpty(this.note.text) && !this.note.id) return of(true);

    if (this.noteForm.invalid) {
      this.noteForm.markAllAsTouched();
      return of(false);
    }

    this.noteLoading = true;

    if (!this.note.id) return this.storeNote();
    else return this.updateNote();
  }

  public storeNote(): Observable<any> {
    return this.productsService.saveNote(this.data.entity_type, this.note).pipe(
      catchError((err) => {
        const message = err?.error?.message;
        this.layoutUtilsService.showActionNotification(message, MessageType.Delete, 10000, true, false);
        this.noteLoading = false;
        return of(false);
      })
    );
  }

  public updateNote(): Observable<any> {
    return this.productsService.updateNote(this.data.entity_type, this.note).pipe(
      catchError((err) => {
        const message = err?.error?.message;
        this.layoutUtilsService.showActionNotification(message, MessageType.Delete, 10000, true, false);
        this.noteLoading = false;
        return of(false);
      })
    );
  }

  getNoteComponentTitle(): string {
    if (this.note?.id) return this.translate.instant("NOTES.ADD.EDIT_TITLE");
    return this.translate.instant("NOTES.ADD.ADD_TITLE");
  }

  deleteNote() {
    if (this.note.id) {
      this.noteLoading = true;
      this.sharedService.destoryNote(this.note.id).subscribe(
        (res) => {
          const message = this.translate.instant("NOTES.ADD.DELETE_BUTTON");
          this.layoutUtilsService.showActionNotification(message, MessageType.Delete, 10000, true, false);
          this.note.clear();
          this.noteForm.controls.text.setValue("");
          this.loadLatestNoteOnPdf();
          return;
        },
        (err) => {
          const message = err?.error?.message ?? this.translate.instant("GENERAL.SOMETHING_WENT_WRONG");
          this.layoutUtilsService.showActionNotification(message, MessageType.Delete, 10000, true, false);
          this.noteLoading = false;
          return;
        }
      );
    }
  }
}
