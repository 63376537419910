// Denmark
export const locale = {
  lang: "da",
  data: {
    NOTE: {
      TITLE: "Note",
      CREDIT_ASSESSMENT: "kreditvurderingsnotat",
    },
    TRANSLATOR: {
      SELECT: "Vælg dit sprog",
      DOWNLOAD: "Hent",
      NOT_PROVIDED: "Ikke angivet",
    },
    MENU: {
      NEW: "Ny",
      ACTIONS: "Handlinger",
      CREATE_POST: "Create New Post",
      APPS: "Apps",
      DASHBOARD: "Oversigt",
      OVERVIEW: "Oversigt",
      APPLICATIONS: "Applikationer",
      TASKS: "Opgaver",
      LISTS: "Overblik",
      ACCOUNTING: "Økonomi",
      ALL_PAYMENTS: "Alle betalinger",
      ALL_TRANSACTIONS: "Alle transaktioner",
      USER_MANAGEMENT: {
        TITLE: "Bruger Administration",
        USERS: "Brugere",
        ROLES: "Roller",
        PERMISSIONS: "Rettigheder",
      },
      VEHICLES_MANAGEMENT: {
        TITLE: "Køretøjer Administration",
        VEHICLES: "Køretøjer",
        MAKES: "Mærker",
      },
      PRODUCTS: "Produkter",
      CONTRACTS: "Kontrakter",
      OFFERS: "Tilbud",
      INSURANCE_PRICES: "Forsikringspriser",
      CUSTOMERS: "Kunder",
      DEALERSHIPS: "Forhandlere",
      SUPPLIERS: "Leverandører",
      SETTINGS: {
        TITLE: "Indstillinger",
        ADMINSTRATION: "Administration",
        VARIABLES: "Funktioner",
      },
      ERROR_PAGES: {
        TITLE: "Error Pages",
        Error_1: "Error 1",
        Error_2: "Error 2",
        Error_3: "Error 3",
      },
      PATCH_NOTE: "Patch Noter",
      FAQS: "FAQs",
    },
    NAVBAR: {
      PROFILE_MMENU: {
        PROFILE: "Profil",
        CHANGE_PASSWORD: "Skift kodeord",
        SETTINGS: "Indstillinger",
        SIGN_OUT: "Log ud",
      },
      QUICK_NAVIGATION_MENU: {
        CUSTOMER: "Kunde",
        ASSET: "Aktiv",
        CONTRACT: "Kontrakt",
        OFFER: "Tilbud",
      },
    },
    SETTINGS: {
      USER_MANAGEMENT: {
        TITLE: "Bruger Administration",
        DOCUMENT_TEMPLATES: {
          TITLE: "Dokument skabeloner",
          DESCRIPTION: "Tilføj og rediger skabeloner.",
        },
        USERS: {
          TITLE: "Brugere",
          DESCRIPTION: "Tilføj og rediger brugere.",
        },
        ROLES: {
          TITLE: "Roller",
          DESCRIPTION: "Tilføj og rediger roller og administrer rettigheder.",
        },
        PERMISSIONS: {
          TITLE: "Rettigheder",
          DESCRIPTION: "Rediger beskrivelse af rettigheder.",
        },
        DEALERSHIPS: {
          TITLE: "Forhandlere",
          DESCRIPTION: "Tilføj og rediger forhandlere.",
        },
        MAKES: {
          TITLE: "Bilmærker",
          DESCRIPTION: "Tilføj og rediger bilmærker.",
        },
        MODELS: {
          TITLE: "Bilmodeller",
          DESCRIPTION: "Tilføj og rediger bilmodeller",
        },
        PRODUCTS: {
          TITLE: "Produkter",
          DESCRIPTION: "Tilføj og rediger produkter.",
        },
        FUNDING_RATES: {
          TITLE: "Finansieringsrente",
          DESCRIPTION: "Tilføj og rediger finansieringsrente.",
        },
        REGISTRATION_TAX_SETTINGS: {
          TITLE: "Indstillinger for registreringsafgift",
          DESCRIPTION: "Tilføj og rediger registreringsafgift",
        },
        DEPARTMENTS: {
          TITLE: "Afdelinger",
          DESCRIPTION: "Tilføj og rediger virksomhedens afdelinger.",
        },
        SYSTEM_TASKS: {
          TITLE: "Systemopgaver",
          DESCRIPTION: "Rediger systemopgavernes prioriterede rækkefølge og hvilken afdeling de skal tildeles.",
        },
        SUPPLIERS: {
          TITLE: "Tilføj eller rediger leverandører",
        },
        REGISTRATION_TAX_INTEREST_RATES: {
          TITLE: "Rente af restafgift",
          DESCRIPTION: "Tilføj og rediger rente af restafgift.",
        },
        VEHICLE_TRACKERS: {
          TITLE: "GPS-trackere til køretøjer",
          DESCRIPTION: "Liste over køretøjer gps trackere fra TrackMe.",
        },
        VEHICLE_TRACKER_USERS: {
          TITLE: "GPS-trackere brugere",
          DESCRIPTION: "Tilføje og redigere tracker-brugere.",
        },
      },
      SYSTEM_TASKS: {
        TITLE: "Systemopgaver",
        NAME: {
          PLACEHOLDER: "Systemopgaver",
          ERROR_MSG: "Systemopgaver er <strong>påkrævet</strong>",
        },
        PREREQUISITES: {
          PLACEHOLDER: "Forudsætninger",
          ERROR_MSG: "Forudsætninger er <strong>påkrævet</strong>",
        },
        DEPARTMENT: {
          PLACEHOLDER: "Tildel afdeling",
          ERROR_MSG: "Afdeling er <strong>påkrævet</strong>",
        },
        DEFAULT_ASSIGNEE: {
          PLACEHOLDER: "standardmodtager",
        },
      },
      CUSTOMERS: {
        TITLE: "Kunder",
        CUSTOM_FIELDS: {
          TITLE: "Brugerdefinerede felter",
          DESCRIPTION: "Tilføj og rediger brugerdefinerede felter og administrer indstillinger for synlighed.",
        },
      },
      JOBS: {
        TITLE: "Funktioner",
        SEND_JOB: "Send job",
        AMORTIZATION_JOB: {
          TITLE: "Afskrivningsjob",
          DESCRIPTION: "Tilføj og rediger afskrivningsjob.",
          ERROR_MSG: "Afskrivningsjob er <strong>påkrævet</strong>",
          MODAL_MESSAGE: "Er du sikker på, at du vil køre afskrivningsjobbet?",
        },
        MILEAGE_JOB: {
          TITLE: "Kilometerjob",
          DESCRIPTION: "Tilføj og rediger kilometerjob.",
          ERROR_MSG: "Kilometerjob er <strong>påkrævet</strong>",
          MODAL_MESSAGE: "Er du sikker på, at du vil køre kilometerjobbet?",
        },
        ECONOMIC_JOBS: {
          TITLE: "Økonomiske job",
          DESCRIPTION: "Tilføj og rediger økonomiske job.",
          ERROR_MSG: "Økonomiske job er <strong>påkrævet</strong>",
          ACCOUNTING_YEARS: "Regnskabsår",
        },
        SYNC_PAYMENT_TERMS: "Synkroniser betalingsbetingelser",
        SYNC_VAT_ACCOUNTS: "Synkroniser moms konti",
        SYNC_JOURNALS: "Synkroniser dagbøger",
        SYNC_SUPPLIER_GROUPS: "Synkroniser leverandørgrupper",
        SYNC_SUPPLIERS: "Synkroniser leverandører",
        SYNC_PRODUCT_GROUPS: "Synkroniser produktgrupper",
        SYNC_LAYOUTS: "Synkroniser layouts",
        SYNC_ACCOUNTING_YEARS: "Synkroniser regnskabsår",
        SYNC_ACCOUNTS: "Synkroniser konti",
        SYNC_INVOICES_STATUSES: "Synkroniser faktura statusser",
        SYNC_TRANSACTION_SET_DOCS: "Synkroniser transaktionsdokumenter",
        SYNC_ACCOUNTING_YEARS_ENTRIES: "Synkroniser regnskabsårposteringer",
        SYNC_ACCOUNTING_YEAR_ENTRIES: "Synkroniser regnskabsår poster",
        RECALCULATE_AMORTIZATION: "Genberegning af afskrivning",
        SYNC_MILEAGE_DATA: "Synkroniser kilometerdata",
        FIELDS: {
          YEAR: "År",
          INPUT_REQUIRED: "Input er påkrævet",
          INPUT_MINLENGTH: "Minimum længde",
          INPUT_MAXLENGTH: "Maksimal længde",
        },
      },
      SYSTEM_VARIABLES: {
        EDIT_TITLE: "Rediger variable",
        CALCULATOR: {
          TITLE: "Leasingberegner",
          EDIT_TITLE: "Rediger leasingberegner",
        },
        GENERAL: {
          TITLE: "Generelt",
          EDIT_TITLE: "Rediger variable",
        },
        FIELDS: {
          NAME: {
            PLACEHOLDER: "Navn",
            ERROR_MSG: "Feltet er <strong>påkrævet</strong>",
          },
          VALUE: {
            PLACEHOLDER: "Værdi",
            ERROR_MSG: "Feltet er <strong>påkrævet</strong>",
          },
          DESCRIPTION: {
            PLACEHOLDER: "Beskrivelse",
            ERROR_MSG: "Feltet er <strong>påkrævet</strong>",
          },
          LOGO: {
            PLACEHOLDER: "Leasing company logo",
            ERROR_MSG: "Feltet er <strong>påkrævet</strong>",
          },
          CONTRACT_ACCOUNT: {
            PLACEHOLDER: "Kontrakt konto",
            ERROR_MSG: "Kontrakt konto <strong>påkrævet</strong>",
            ADD_ANOTHER_LINE: "Tilføj linie",
            REMOVE_THIS_LINE: "Fjern linie",
            DESCRIPTION: "Liste over konti, der påvirker kontraktværdien",
          },
          INVOICE_LAYOUT: {
            PLACEHOLDER: "Førstegangsydelse",
          },
        },
        ADDON_PRODUCTS: {
          TITLE: "Produkter",
          DESCRIPTION: "Tilføj og rediger produkter og administrer indstillinger for synlighed.",
        },
        TAX_INTERESTS: {
          TITLE: "Rentesats registreringsafgift",
          DESCRIPTION: "Administrer rentesatsen som bruges til at beregne den forholdsmæssige registreringsafgift.",
        },
        INTERESTS_RATE: {
          TITLE: "Rentesats betalinger",
          DESCRIPTION: "Administrer rentesatsen som bruges til at beregne den månedlige ydelse.",
        },
        VARIABLE_INTEREST_RATE: {
          PLACEHOLDER: "Variabel rente",
          ERROR_MSG: "Variabel rente <strong>påkrævet</strong>",
          DESCRIPTION: "Standard mark-up på referencerente og Reference-rente",
          CONFIRMATION: {
            DESCRIPTION: "Du har ændret reference-lejen. Er du sikker?",
          },
        },
        MONTHIO_SETTINGS: {
          PLACEHOLDER: "Monthio Service",
          ERROR_MSG: "Legitimationsoplysninger <strong>påkrævet</strong>",
          DESCRIPTION: "kreditværdighedsløsning for at gøre lånegodkendelse hurtigere, nemmere og mere præcis.",
          CLIENT_KEY: "Monthio API Nøgle",
          CLIENT_SECRET: "Monthio API Hemmelighed",
        },
        REQUIRED_FIELDS_SETTINGS: {
          TITLE: "Krævede Felter",
          DEFAULT_FORM: "Standardformular",
          CONTRACT_FORM: "Kontraktformular",
          OFFER_FORM: "Tilbudsformular",
          CUSTOMER_FIELDS: "Kundefelter",
          VEHICLE_FIELDS: "Køretøjsfelter",
        },
        TEMPLATE_CUSTOM_FIELDS_SETTINGS: {
          TITLE: "Skabelon brugerdefinerede felter",
          MERGE_FIELD: "Flette Feltnavn",
          FIELD_NAME: "Feltnavn",
          FIELD_TYPE: "Felttype",
          HAS_DEFAULT: "Har Standard",
          FIELD_DEFAULT: "Felt Standard",
        },
        PRODUCT_ADJUSTMENT_SETTINGS: {
          TITLE: "Produktjustering",
          DESCRIPTION: "Administrer produktjustering.",
          ERROR_MSG: "Produktjustering er <strong>påkrævet</strong>",
          PLACE_HOLDER: "Produktjustering",
          ADD_PRODUCT: "Tilføj produkt",
        },
        CREDITRO_SETTINGS: {
          PLACEHOLDER: "Creditro Service",
          ERROR_MSG: "Legitimationsoplysninger <strong>påkrævet</strong>",
          TOKEN: "Autentificeringstoken",
        },
        FARPAY_SETTINGS: {
          PLACEHOLDER: "FarPay Service",
          ERROR_MSG: "Legitimationsoplysninger <strong>påkrævet</strong>",
          TOKEN: "Autentificeringstoken",
          DEBITOR_GROUP_NUMBER: "Debitornummer",
        },
        DBI_SETTINGS: {
          PLACEHOLDER: "DBI Service",
          ERROR_MSG: "Legitimationsoplysninger <strong>påkrævet</strong>",
          USERNAME: "Brugernavn",
          PASSWORD: "Adgangskode",
        },
        TRACKME_SETTINGS: {
          PLACEHOLDER: "TrackMe Service",
          ERROR_MSG: "type er <strong>påkrævet</strong>",
          ADD_TRACKER_TYPE: "Tilføj tracker type",
        },
        MARKUP_INTEREST_RATE: {
          TITLE: "Markup på referencerente",
        },
        BENCHMARK_INTEREST_RATE: {
          TITLE: "Reference-rente",
        },
        DEPRECIATION_RATE: {
          TITLE: "Afskrivningsprocent",
          DESCRIPTION: "Administrer den årlige standard afskrivningsprocent som bruges til at beregne restværdien.",
        },
        INCOME_DOCUMENT_EXPIRATION: {
          TITLE: "Indkomstdokumentindstillinger",
          MONTHS: "Måneder",
          DAYS: "Dage",
        },
      },
      CUSTOM: {
        GENERAL: {
          TITLE: "brugerdefinerede",
        },
        COMPANY_INFO: {
          TITLE: "virksomhedsoplysninger",
          DESCRIPTION: "opdatere virksomhedsoplysninger",
          FIELDS: {
            NAME: {
              TITLE: "Firmanavn",
            },
            CITY: {
              TITLE: "Virksomhedens by",
            },
            POSTAL_CODE: {
              TITLE: "Postnummer",
            },
            PHONE_NUMBER: {
              TITLE: "Telefonnummer",
            },
            CVR_NUMBER: {
              TITLE: "CVR",
            },
            COMPANY_EMAILS: {
              TITLE: "Emails",
            },
            SEND_STATUS_UPDATES: {
              TITLE: "send status til virksomhedens kunder",
            },
          },
        },
        SIGNATURE: {
          TITLE: "Underskrift",
          DESCRIPTION: "konfigurere signatur",
          FIELDS: {
            APPLY_SIGNATURE: {
              TITLE: "anvende digital signering",
            },
            USE_SAME_TEMPLATE: {
              TITLE: "bruge samme skabelon til både kunde og virksomhed",
              YES: "Ja",
              NO: "ingen",
            },
            DEPARTMENT: {
              TITLE: "Afdeling",
            },
          },
        },
      },
      ADMIN_SETTINGS: {
        TITLE: "Opslag",
        ENTER_SETTING_NAME: "Indtast indstillingsnavn",
        REQUIRED_FIELD: "Påkrævet felt",
        ADD_NEW_SETTING: "Tilføj ny indstilling",
        EDIT_EXISTING_SETTING: "Rediger Eksisterende Indstilling",
        CONFIRM_DIALOG: {
          BODY: "Er du sikker på, at du vil slette dette?",
          HEADER: "Konfirmation",
        },
        TABLE_COLUMNS: {
          NAME: "Navn",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
        },
        EVALUATION_SOURCE: {
          CREATE: "Oprettet Vurderingskilder",
          EDIT: "Rediger Vurderingskilder",
          TITLE: "Vurderingskilder",
          DESCRIPTION: "Tilføj og rediger Vurderingskilder.",
          BUTTON: {
            ADD_NEW: "Ny Vurderingskilder",
          },
          ERROR: {
            UNABLE_TO_LOAD: "Kunne ikke indlæse Vurderingskilder",
          },
        },
        EVALUATION_TYPE: {
          CREATE: "Oprettet Vurderingstyper",
          EDIT: "Rediger Vurderingstyper",
          TITLE: "Vurderingstyper",
          DESCRIPTION: "Tilføj og rediger Vurderingstyper.",
          BUTTON: {
            ADD_NEW: "Ny Vurderingstyper",
          },
          ERROR: {
            UNABLE_TO_LOAD: "Kunne ikke indlæse Vurderingstyper",
          },
        },
        INSURANCE_COMPANY: {
          CREATE: "Oprettet Forsikringsselskaber",
          EDIT: "Rediger Forsikringsselskaber",
          TITLE: "Forsikringsselskaber",
          DESCRIPTION: "Tilføj og rediger Forsikringsselskaber.",
          BUTTON: {
            ADD_NEW: "Ny Forsikringsselskaber",
          },
          ERROR: {
            UNABLE_TO_LOAD: "Kunne ikke indlæse Forsikringsselskaber",
          },
        },
        VEHICLE_TYPE: {
          CREATE: "Oprettet Køretøjstyper",
          EDIT: "Rediger Køretøjstyper",
          TITLE: "Køretøjstyper",
          DESCRIPTION: "Tilføj og rediger Køretøjstyper.",
          BUTTON: {
            ADD_NEW: "Ny Køretøjstyper",
          },
          ERROR: {
            UNABLE_TO_LOAD: "Kunne ikke indlæse Køretøjstyper",
          },
        },
        VEHICLE_CONDITION: {
          CREATE: "Oprettet Køretøjets stand",
          EDIT: "Rediger Køretøjets stand",
          TITLE: "Køretøjets stand",
          DESCRIPTION: "Tilføj og rediger Køretøjets stand.",
          BUTTON: {
            ADD_NEW: "Ny Køretøjets stand",
          },
          ERROR: {
            UNABLE_TO_LOAD: "Kunne ikke indlæse Køretøjets stand",
          },
        },
        NOTE_CATEGORIES: {
          CREATE: "Oprettet Note kategorier",
          EDIT: "Rediger Note kategorier",
          TITLE: "Note kategorier",
          DESCRIPTION: "Tilføj og rediger Note kategorier.",
          BUTTON: {
            ADD_NEW: "Ny Note kategorier",
          },
          ERROR: {
            UNABLE_TO_LOAD: "Kunne ikke indlæse Note kategorier",
          },
          FIELD: {
            IS_EXTERNAL: "Note kan læses af eksterne brugere med rettighed hertil",
          },
        },
      },
    },
    AUTH: {
      GENERAL: {
        OR: "Eller",
        SUBMIT_BUTTON: "Send",
        NO_ACCOUNT: "Don't have an account?",
        SIGNUP_BUTTON: "Sign Up",
        FORGOT_BUTTON: "Glemt kodeord",
        BACK_BUTTON: "Tilbage",
        PRIVACY: "Privacy",
        LEGAL: "Legal",
        CONTACT: "Kontakt",
        WELCOME_SITE: "Velkommen til",
        WELCOME_SLOGAN: "Dit administrationssystem til hele din forretning",
      },
      LOGIN: {
        TITLE: "Login Konto",
        BUTTON: "Log ind",
      },
      FORGOT: {
        TITLE: "Glemt kodeord?",
        DESC: "Indtast din email for at nulstille dit kodeord",
        SUCCESS: "Et link til nulstilling af din kode er sendt til din e-mail",
      },
      REGISTER: {
        TITLE: "Sign Up",
        DESC: "Enter your details to create your account",
        SUCCESS: "Your account has been successfuly registered.",
      },
      RESET_PASSWORD: {
        TITLE: "Nulstil Kodeord",
        DESC: "Indtast dine detaljer for at nulstille dit kodeord",
        SUCCESS: "Dit kodeord er nu nulstillet.",
      },
      INPUT: {
        EMAIL: "Email",
        FULLNAME: "Navn",
        PASSWORD: "Kodeord",
        CONFIRM_PASSWORD: "Gentag kodeord",
        USERNAME: "Brugernavn",
      },
      VALIDATION: {
        INVALID: "{{name}} er ikke gyldigt",
        REQUIRED: "{{name}} er påkrævet",
        MIN_LENGTH: "{{name}} minimum længde er {{min}}",
        AGREEMENT_REQUIRED: "Det er påkrævet at acceptere vilkår og betingelser",
        NOT_FOUND: "Det anmodede {{name}} blev ikke fundet",
        INVALID_LOGIN: "Login detaljerne er ikke korrekte",
        REQUIRED_FIELD: "Påkrævet felt",
        MIN_LENGTH_FIELD: "Minimum antal krævede tegn:",
        MAX_LENGTH_FIELD: "Maksimum antal krævede tegn:",
        INVALID_FIELD: "Feltet er ikke gyldigt",
      },
    },
    USER_PROFILE: {
      TITLE: "Brugerprofil",
      CHANGE_PASSWORD: {
        TITLE: "Skift kodeord",
        FIELDS: {
          OLD_PASSWORD: {
            NAME: "Indtast <strong> gammelt kodeord</strong>",
            PLACEHOLDER: "Indtast gammelt kodeord",
            ERROR_MSG: "gammelt kodeord er <strong>påkrævet</strong>",
          },
          NEW_PASSWORD: {
            NAME: "Indtast <strong> nyt kodeord</strong>",
            PLACEHOLDER: "Indtast nyt kodeord",
            ERROR_MSG: "nyt kodeord er <strong>påkrævet</strong>",
          },
          NEW_PASSWORD_CONFIRMATION: {
            NAME: "Gentag <strong> nyt kodeord</strong>",
            PLACEHOLDER: "Gentag nyt kodeord",
            ERROR_MSG: "Gentag nyt kodeord er <strong>påkrævet</strong>",
          },
        },
      },
    },
    USERS: {
      VIEW: {
        TITLE: "Brugere",
        HEAD_TITLE: "Administrer brugere",
        NEW_BUTTON: "Ny bruger",
        NEW_BUTTON_TOOLTIP: "Opret en ny bruger",
        EDIT_BUTTON: "Rediger bruger",
        DELETE_BUTTON: "Slet bruger",
        TABLE_COLUMNS: {
          PHOTO: "Foto",
          NAME: "Navn",
          EMAIL: "Email",
          ROLE: "Rolle",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
        },
        DELETE_MODAL: {
          TITLE: "Slet bruger",
          DESCRIPTION: "Vælg venligst en medarbejder til overtagelse af opgaver og kunder.",
          NO_USERS_IN_DEPARTMENT: "Der er ingen aktive ansatte i samme afdeling at overdrage opgaver og kunder til.",
          WAIT_DESCRIPTION: "Bruger bliver slettet...",
          CHOOSE_USER: "Vælg bruger",
          MOVE_USER_TASKS: "Alle opgaver og kunder vil blive overdraget til den nye valgte bruger",
        },
      },
      ADD: {
        ADD_TITLE: "Opret ny bruger",
        EDIT_TITLE: "Rediger bruger",
        FIELDS: {
          NAME: {
            PLACEHOLDER: "Navn",
            ERROR_MSG: "Navn er <strong>påkrævet</strong>",
          },
          EMAIL: {
            PLACEHOLDER: "Email",
            ERROR_MSG: "Email er <strong>påkrævet</strong>",
          },
          CPR: {
            PLACEHOLDER: "CPR",
            ERROR_MSG: "CPR er <strong>påkrævet</strong>",
          },
          ROLE: {
            PLACEHOLDER: "Rolle",
            ERROR_MSG: "Rolle er <strong>påkrævet</strong>",
          },
          IS_ACTIVE: {
            PLACEHOLDER: "Bruger er aktiv",
          },
          IS_DEALERSHIP: {
            PLACEHOLDER: "Bruger er tilknyttet forhandler",
          },
          IS_DEPARTMENT_HEAD: {
            PLACEHOLDER: "sat som afdelingsleder",
          },
          DEALERSHIP: {
            PLACEHOLDER: "Vælg forhandler",
            ERROR_MSG: "Forhandler er <strong>påkrævet</strong>",
          },
          PROFILE_PICTURE: {
            NAME: "Profilbillede",
            PLACEHOLDER: "Indsæt dit billede her..",
          },
          ON_VACATION: {
            PLACEHOLDER: "Bruger er på ferie",
          },
          VACATION_UNTIL: {
            PLACEHOLDER: "Bruger er på ferie indtil",
            ERROR_MSG: "Ferie indtil er <strong>påkrævet</strong>",
          },
          DEPARTMENT: {
            PLACEHOLDER: "Afdeling",
            ERROR_MSG: "Afdeling er <strong>påkrævet</strong>",
          },
          AUTHORIZED_FOR_CONTRACT_SIGN: {
            PLACEHOLDER: "bruger er bemyndigSet til at underskrive en kontrakt",
          },
        },
      },
    },
    ROLES: {
      VIEW: {
        TITLE: "Roller",
        HEAD_TITLE: "Administrer roller",
        NEW_BUTTON: "Ny rolle",
        NEW_BUTTON_TOOLTIP: "Opret ny rolle",
        EDIT_BUTTON: "Rediger rolle",
        DELETE_BUTTON: "Slet rolle",
        TABLE_COLUMNS: {
          NAME: "Navn",
          IS_SYSTEM: "System-rolle",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
        },
        DELETE_MODAL: {
          TITLE: "Slet rolle",
          DESCRIPTION:
            "Er du sikker på, at du permanent vil slette denne rolle? Hvis ja, så vælg en rolle der skal erstatte denne.",
          WAIT_DESCRIPTION: "Rolle bliver slettet...",
          FIELDS: {
            NEW_ROLE: {
              PLACEHOLDER: "Ny rolle",
            },
          },
        },
      },
      ADD: {
        ADD_TITLE: "Opret ny rolle",
        EDIT_TITLE: "Rediger rolle",
        FIELDS: {
          TITLE: {
            PLACEHOLDER: "Rollenavn",
            ERROR_MSG: "Rollenavn er <strong>påkrævet</strong>",
          },
          PERMISSIONS: {
            PLACEHOLDER: "Rettigheder:",
          },
        },
      },
    },
    PERMISSIONS: {
      CATEGORIES: {
        USERS_MANAGEMENT: "Bruger administration",
        ROLES_MANAGEMENT: "Rolle administration",
        PERMISSIONS_MANAGEMENT: "Rettigheder administration",
        CUSTOMERS_MANAGEMENT: "Kunde administration",
        DEALERSHIPS_MANAGEMENT: "Forhandler administration",
        ASSETS_MANAGEMENT: "Aktiv administration",
        VEHICLES_MANAGEMENT: "Køretøj administration",
        VEHICLE_MAKES_MANAGEMENT: "Bilmærke administration",
        SETTINGS_MANAGEMENT: "Indstillinger administration",
        PRODUCTS_MANAGEMENT: "Produkt administration",
        FUNDING_RATES_MANAGEMENT: "Finansieringsrente administration",
        LOOKUPS_MANAGEMENT: "Opslag administration",
        CONTRACTS_MANAGEMENT: "Kontrakt administration",
        DEPARTMENTS_MANAGEMENT: "Afdelinger administration",
        TASKS_MANAGEMENT: "Opgave administration",
        SUPPLIERS_MANAGEMENT: "Leverandør administration",
        OFFERS_MANAGEMENT: "Leverandør administration",
        ACCOUNTING_MANAGEMENT: "Regnskab administration",
        NOTES_MANAGEMENT: "noter ledelse",
        CUSTOMER_RISK_CLASSIFICATION_MANAGEMENT: "Kunde Risikoklassificering Administration",
        OFFER_CALCULATOR_FIELDS: "Tilby lommeregnerfelter",
        CONTRACT_CALCULATOR_FIELDS: "Felter til lommeregnerfelter",
        REGISTRATION_INTEREST_RATE_MANAGEMENT: "Rente af restafgift administration",
      },
      VIEW: {
        TITLE: "Rettigheder",
        HEAD_TITLE: "Administrer rettigheder",
        NEW_BUTTON: "Ny rettighed",
        NEW_BUTTON_TOOLTIP: "Opret ny rettighed",
        EDIT_BUTTON: "Rediger rettighed",
        DELETE_BUTTON: "Slet rettighed",
        TABLE_COLUMNS: {
          NAME: "Navn",
          DESCRIPTION: "Beskrivelse",
          CATEGORY: "Kategori",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
        },
        DELETE_MODAL: {
          TITLE: "Slet rettighed",
          DESCRIPTION: "Er du sikker på, at du permanent vil slette denne rettighed?",
          WAIT_DESCRIPTION: "Rettighed bliver slettet...",
        },
      },
      ADD: {
        ADD_TITLE: "Opret ny rettighed",
        EDIT_TITLE: "Rediger rettighed",
        FIELDS: {
          TITLE: {
            PLACEHOLDER: "Titel",
            ERROR_MSG: "Titel er <strong>påkrævet</strong>",
          },
          DESCRIPTION: {
            PLACEHOLDER: "Indtast beskrivelse",
            ERROR_MSG: "Beskrivelse er <strong>påkrævet</strong>",
          },
        },
      },
    },
    TEMPLATES: {
      TYPES: {
        CONTRACT: "Kontrakt",
        OFFER: "Tilbud",
        PAYMENT_ADVICE: "Betalingsråd",
        CONTRACT_ATTACHMENT: "Kontraktbilag",
      },
      VIEW: {
        TITLE: "Dokument skabeloner",
        HEAD_TITLE: "Administrer skabeloner",
        NEW_BUTTON: "Ny skabelon",
        NEW_BUTTON_TOOLTIP: "Opret ny skabelon",
        EDIT_BUTTON: "Rediger skabelon",
        DELETE_BUTTON: "Slet skabelon",
        TABLE_COLUMNS: {
          NAME: "Navn",
          TYPE: "Type",
          CONTRACT_TYPE: "Kontrakt type",
          ASSET_TYPE: "Aktiv type",
          LEASING_TYPE: "Leasing type",
          FILE: "Fil",
        },
        DELETE_MODAL: {
          TITLE: "Slet skabelon",
          DESCRIPTION: "Er du sikker på, at du permanent vil slette denne skabelon?",
          WAIT_DESCRIPTION: "Skabelon bliver slettet...",
        },
      },
      ADD: {
        ADD_TITLE: "Opret ny skabelon",
        EDIT_TITLE: "Rediger skabelon",
        FIELDS: {
          NAME: {
            PLACEHOLDER: "Navn",
            ERROR_MSG: "Navn er <strong>påkrævet</strong>",
          },
          TYPE: {
            PLACEHOLDER: "Dokument type",
            ERROR_MSG: "Dokument type er <strong>påkrævet</strong>",
          },
          IS_USED_VAT: {
            PLACEHOLDER: "Brugtmoms",
          },
          CONTRACT_TYPE: {
            PLACEHOLDER: "Kontrakt-type",
            ERROR_MSG: "Kontrakt-type er <strong>påkrævet</strong>",
          },
          OFFER_TYPE: {
            PLACEHOLDER: "Tilbudstype",
            ERROR_MSG: "Tilbudstype er <strong>påkrævet</strong>",
          },
          ASSET_TYPE: {
            PLACEHOLDER: "Aktiv-type",
            ERROR_MSG: "Aktiv-type er <strong>påkrævet</strong>",
          },
          LEASING_TYPE: {
            PLACEHOLDER: "Leasing-type",
            ERROR_MSG: "Leasing-type er <strong>påkrævet</strong>",
          },
          FILE: {
            PLACEHOLDER: "Fil",
            ERROR_MSG: "Fil er <strong>påkrævet</strong>",
          },
        },
      },
    },
    DEALERSHIPS: {
      VIEW: {
        TITLE: "Forhandlere",
        HEAD_TITLE: "Administrer forhandlere",
        NEW_BUTTON: "Ny forhandler",
        NEW_BUTTON_TOOLTIP: "Opret ny forhandler",
        EDIT_BUTTON: "Rediger forhandler",
        DELETE_BUTTON: "Slet forhandler",
        TABLE_COLUMNS: {
          NAME: "Navn",
          CVR_NUMBER: "CVR",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
        },
        DELETE_MODAL: {
          TITLE: "Slet forhandler",
          DESCRIPTION: "Er du sikker på, at du permanent vil slette denne forhandler?",
          WAIT_DESCRIPTION: "Forhandler bliver slettet...",
        },
      },
      ADD: {
        ADD_TITLE: "Opret ny forhandler",
        EDIT_TITLE: "Rediger forhandler",
        FIELDS: {
          NAME: {
            PLACEHOLDER: "Virksomhedens navn",
            HINT_PLACEHOLDER: "Søg på <strong>CVR</strong> eller <strong>virksomhedsnavn</strong>",
            ERROR_MSG: "Navn er <strong>påkrævet</strong>",
          },
          CVR_NUMBER: {
            PLACEHOLDER: "CVR",
            ERROR_MSG: "CVR er <strong>påkrævet</strong>",
          },
          EMAIL: {
            PLACEHOLDER: "E-mail",
            ERROR_MSG: "E-mail er <strong>påkrævet</strong>",
            INVALID: "E-mail skal være en gyldig e-mailadresse",
          },
          PHONE: {
            PLACEHOLDER: "Telefonnr.",
            ERROR_MSG: "Telefonnr. er <strong>påkrævet</strong>",
          },
          CITY: {
            PLACEHOLDER: "By",
            ERROR_MSG: "By er <strong>påkrævet</strong>",
          },
          POSTAL_CODE: {
            PLACEHOLDER: "Postnummer",
            ERROR_MSG: "Postnummer er <strong>påkrævet</strong>",
          },
          ADDRESS: {
            PLACEHOLDER: "Adresse",
            ERROR_MSG: "Adresse er <strong>påkrævet</strong>",
          },
          INCLUDE_FEE: {
            PLACEHOLDER: "Inkluder opsætningsgebyrer",
          },
          SETUP_FEE_AMOUNT: {
            PLACEHOLDER: "Beløb for oprettelsesgebyr",
            VALIDATIONS: {
              REQUIRED: "Beløb er <strong>påkrævet</strong>",
              MIN: "Mindste værdi er 1",
            },
          },
        },
      },
    },
    NOTES: {
      VIEW: {
        TITLE: "Noter",
        HEAD_TITLE: "Noter",
      },
      ADD: {
        ADD_TITLE: "Opret note",
        ADD_BUTTON: "Note",
        EDIT_TITLE: "Rediger note",
        SAVE: "Gemme",
        FIELDS: {
          CUSTOMER: {
            LABEL_ALL: "Gammel Kunde",
            LABEL: "Kunde",
            ERROR_MSG: "kunde er <strong>påkrævet</strong>",
          },
          CATEGORY: {
            LABEL: "Kategori",
            ERROR_MSG: "Kategori er <strong>påkrævet</strong>",
          },
          TEXT: {
            LABEL: "Skriv en note",
            ERROR_MSG: "Note er <strong>påkrævet</strong>",
          },
        },
        DELETE_BUTTON: "Slet Note",
        DELETE_SUCCESS: "Noten blev slettet",
        CREATE_SUCCESS: "Noten er blevet oprettet med succes.",
        UPDATE_SUCCESS: "Noten er blevet opdateret med succes.",
      },
    },
    SUPPLIERS: {
      VIEW: {
        TITLE: "Leverandører",
        NEW_BUTTON: "Ny leverandør",
        NEW_BUTTON_TOOLTIP: "Opret ny leverandør",
        EDIT_BUTTON: "Rediger leverandør",
        DELETE_BUTTON: "Slet leverandør",
        TABLE_COLUMNS: {
          NAME: "Navn",
          SUPPLIER_CVR: "CVR",
          SUPPLIER_GROUP: "Leverandør gruppe",
          PAYMENT_TERMS: "Betalingsbetingelser",
        },
        DELETE_MODAL: {
          TITLE: "Slet leverandør",
          DESCRIPTION: "Er du sikker på, at du permanent vil slette denne leverandør?",
          WAIT_DESCRIPTION: "Leverandør bliver slettet...",
        },
      },
      ADD: {
        ADD_TITLE: "Opret ny leverandør",
        EDIT_TITLE: "Rediger leverandør",
        FIELDS: {
          NAME: {
            PLACEHOLDER: "Virksomhedens navn",
            HINT_PLACEHOLDER: "Søg på <strong>CVR</strong> eller <strong>virksomhedsnavn</strong>",
            ERROR_MSG: "Navn er <strong>påkrævet</strong>",
          },
          SUPPLIER_CVR: {
            PLACEHOLDER: "CVR",
            ERROR_MSG: "CVR er <strong>påkrævet</strong>",
          },
          SUPPLIER_GROUP: {
            PLACEHOLDER: "Leverandør gruppe",
            ERROR_MSG: "Leverandør gruppe er <strong>påkrævet</strong>",
          },
          PAYMENT_TERMS: {
            PLACEHOLDER: "Betalingsbetingelser",
            ERROR_MSG: "Betalingsbetingelser er <strong>påkrævet</strong>",
          },
          PAYMENT_TYPE: {
            PLACEHOLDER: "Betalingstype",
            ERROR_MSG: "Betalingstype er <strong>påkrævet</strong>",
          },
          ACCOUNT_NO: {
            PLACEHOLDER: "Kontonr. (inkl. reg.nr.)",
            ERROR_MSG: "Kontonr. (inkl. reg.nr.) er <strong>påkrævet</strong>",
          },
          CREDIT_ID: {
            PLACEHOLDER: "creditorId",
            ERROR_MSG: "creditorId er <strong>påkrævet</strong>",
          },
        },
      },
    },
    FUNDING_RATES: {
      VIEW: {
        TITLE: "Finansieringsrente",
        HEAD_TITLE: "Administrer finansieringsrente",
        NEW_BUTTON: "Ny finansieringsrente",
        NEW_BUTTON_TOOLTIP: "Opret ny finansieringsrente",
        EDIT_BUTTON: "Rediger finansieringsrente",
        DELETE_BUTTON: "Slet finansieringsrente",
        TABLE_COLUMNS: {
          EFFECTIVE_FROM: "Gældende fra",
          FUNDING_RATE: "Finansieringsrente",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
        },
        DELETE_MODAL: {
          TITLE: "Slet finansieringsrente",
          DESCRIPTION: "Er du sikker på, at du permanent vil slette denne finansieringsrente?",
          WAIT_DESCRIPTION: "finansieringsrente bliver slettet...",
        },
      },
      ADD: {
        ADD_TITLE: "Opret ny finansieringsrente",
        EDIT_TITLE: "Rediger finansieringsrente",
        FIELDS: {
          EFFECTIVE_FROM: {
            PLACEHOLDER: "Gældende fra",
            ERROR_MSG: "Gældende fra er <strong>påkrævet</strong>",
          },
          FUNDING_RATE: {
            PLACEHOLDER: "Finansieringsrente",
            ERROR_MSG: "Finansieringsrente er <strong>påkrævet</strong>",
          },
        },
      },
    },
    REGISTRATION_TAX_INTEREST_RATES: {
      VIEW: {
        TITLE: "Rente af restafgift",
        HEAD_TITLE: "Administrer rente af restafgift",
        NEW_BUTTON: "Ny rente af restafgift",
        NEW_BUTTON_TOOLTIP: "Opret ny rente af restafgift",
        EDIT_BUTTON: "Rediger rente af restafgift",
        DELETE_BUTTON: "Slet rente af restafgift",
        TABLE_COLUMNS: {
          DATE: "Gældende fra",
          INTEREST_RATE: "Rentesats",
        },
        DELETE_MODAL: {
          TITLE: "Slet rente af restafgift",
          DESCRIPTION: "Er du sikker på, at du permanent vil slette denne rente af restafgift?",
          WAIT_DESCRIPTION: "rente af restafgift bliver slettet...",
        },
      },
      ADD: {
        ADD_TITLE: "Opret ny rente af restafgift",
        EDIT_TITLE: "Rediger rente af restafgift",
        FIELDS: {
          DATE: {
            PLACEHOLDER: "Gældende fra",
            ERROR_MSG: "Gældende fra er <strong>påkrævet</strong>",
          },
          INTEREST_RATE: {
            PLACEHOLDER: "rente af restafgift",
            ERROR_MSG: "rente af restafgift er <strong>påkrævet</strong>",
          },
        },
      },
    },
    CUSTOMER_RISK_CLASSIFICATION: {
      VIEW: {
        TITLE: "Kunde Risikoklassificering",
        HEAD_TITLE: "Kunde Risikoklassificering",
        NEW_BUTTON: "Ny klassifikation",
        NEW_BUTTON_TOOLTIP: "Opret ny Risikoklassificering",
        EDIT_BUTTON: "Rediger afdeling",
        DELETE_BUTTON: "Slet Risikoklassificering",
        TABLE_COLUMNS: {
          NAME: "Navn",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
        },
        DELETE_MODAL: {
          TITLE: "Slet Risikoklassificering",
          DESCRIPTION:
            "Er du sikker på, at du permanent vil slette denne Risikoklassificering? Vælg en Risikoklassificering, som brugerne i den slettede Risikoklassificering skal flyttes til.",
          WAIT_DESCRIPTION: "Risikoklassificering bliver slettet...",
        },
      },
      ADD: {
        ADD_TITLE: "Opret ny Risikoklassificering",
        EDIT_TITLE: "Rediger Risikoklassificering",
        FIELDS: {
          NAME: {
            PLACEHOLDER: "Navn",
            ERROR_MSG: "Navn er <strong>påkrævet</strong>",
          },
        },
      },
    },
    REGISTRATION_TAX_SETTINGS: {
      VIEW: {
        TITLE: "Indstillinger for registreringsafgift",
        HEAD_TITLE: "Administrer beregningsvariableer",
        NEW_BUTTON: "Ny beregningsvariable",
        NEW_BUTTON_TOOLTIP: "Opret ny beregningsvariable",
        EDIT_BUTTON: "Rediger beregningsvariable",
        DELETE_BUTTON: "Slet beregningsvariable",
        TABLE_COLUMNS: {
          EFFECTIVE_FROM: "Gældende fra",
          REGISTRATION_TAX: "beregningsvariable",
          VALUE: "VÆRDI",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
        },
        DELETE_MODAL: {
          TITLE: "Slet beregningsvariable",
          DESCRIPTION: "Er du sikker på, at du permanent vil slette denne beregningsvariable?",
          WAIT_DESCRIPTION: "Beregningsvariable bliver slettet...",
        },
      },
      ADD: {
        ADD_TITLE: "Opret ny beregningsvariable",
        EDIT_TITLE: "Rediger beregningsvariable",
        FIELDS: {
          EFFECTIVE_FROM: {
            PLACEHOLDER: "Gældende fra",
            ERROR_MSG: "Gældende fra er <strong>påkrævet</strong>",
          },
          REGISTRATION_TAX: {
            PLACEHOLDER: "beregningsvariable",
            ERROR_MSG: "Beregningsvariable er <strong>påkrævet</strong>",
          },
          REGISTRATION_TAX_VALUE: {
            PLACEHOLDER: "Værdi",
            ERROR_MSG: "Værdi er <strong>påkrævet</strong>",
          },
        },
      },
    },
    DEPARTMENTS: {
      VIEW: {
        TITLE: "Afdelinger",
        HEAD_TITLE: "Administrer afdelinger",
        NEW_BUTTON: "Ny afdeling",
        NEW_BUTTON_TOOLTIP: "Opret ny afdeling",
        EDIT_BUTTON: "Rediger afdeling",
        DELETE_BUTTON: "Slet afdeling",
        TABLE_COLUMNS: {
          NAME: "Navn",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
        },
        DELETE_MODAL: {
          TITLE: "Slet afdeling",
          DESCRIPTION:
            "Er du sikker på, at du permanent vil slette denne afdeling? Vælg en afdeling, som brugerne i den slettede afdeling skal flyttes til.",
          WAIT_DESCRIPTION: "Afdeling bliver slettet...",
        },
      },
      ADD: {
        ADD_TITLE: "Opret ny afdeling",
        EDIT_TITLE: "Rediger afdeling",
        FIELDS: {
          NAME: {
            PLACEHOLDER: "Afdelingsnavn",
            ERROR_MSG: "Afdelingsnavn er <strong>påkrævet</strong>",
          },
        },
      },
    },
    CUSTOMERS: {
      VIEW: {
        TITLE: "Kunder",
        OLD_CUSTOMER: "Gammel kunde",
        NEW_CUSTOMER: "Ny kunde",
        HEAD_TITLE: "Administrer kunder",
        NEW_BUTTON: "Ny kunde",
        NEW_BUTTON_TOOLTIP: "Opret ny kunde",
        EDIT_BUTTON: "Rediger kunde",
        DELETE_BUTTON: "Slet kunde",
        CUSTOMER_TYPE_CORPORATE: "Erhverv",
        CUSTOMER_TYPE_PRIVATE: "Privat",
        ASSIGN_MANAGER_BUTTON: "Tildel kundeansvarlig",
        ASSIGN_MANAGER_BUTTON_TOOLTIP: "Tildel kundeansvarlig til de valgte kunder",
        TABLE_COLUMNS: {
          NUMBER: "Nummer",
          TYPE: "Type",
          ADDRESS: "Adresse",
          NAME: "Navn",
          CUSTOMER_ID: "Kunde ID",
          EMAIL: "Email",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
        },
        DELETE_MODAL: {
          TITLE: "Slet kunde",
          DESCRIPTION: "Er du sikker på, at du permanent vil slette denne kunde?",
          WAIT_DESCRIPTION: "Kunde bliver slettet...",
        },
      },
      ADD: {
        ADD_TITLE: "Opret ny kunde",
        EDIT_TITLE: "Rediger kunde",
        FIELDS: {
          NEW_CUSTOMER: {
            PLACEHOLDER: "Ny kunde",
            ERROR_MSG: "Ny kunde er <strong>påkrævet</strong>",
          },
          OLD_CUSTOMER: {
            PLACEHOLDER: "Gammel Kunde",
            ERROR_MSG: "Ny kunde er <strong>påkrævet</strong>",
          },
          DATE: {
            PLACEHOLDER: "Dato",
            COUNT: "Måneder tæller",
            ERROR_MSG: "Dato er <strong>påkrævet</strong>",
          },
          MONTHS_COUNT: {
            PLACEHOLDER: "Antal måneder",
            ERROR_MSG: "Ugyldige <strong>måneder</strong>",
          },
          NAME: {
            PLACEHOLDER: "Navn",
            ERROR_MSG: "navn er <strong>påkrævet</strong>",
            COMPANY_CONTACT_NAME: "Firmaets kontaktperson",
            ERROR_MSG_COMPANY_CONTACT_NAME: "Firmaets kontaktperson er <strong>påkrævet</strong>",
          },
          COUNTRY: {
            PLACEHOLDER: "Land",
            ERROR_MSG: "Land er <strong>påkrævet</strong>",
          },
          CURRENCY: {
            PLACEHOLDER: "Valuta",
            ERROR_MSG: "Valuta er <strong>påkrævet</strong>",
          },
          RISK_CLASS: {
            PLACEHOLDER: "RISIKONIVEAU",
            ERROR_MSG: "RISIKONIVEAU er <strong>påkrævet</strong>",
          },
          EMAIL: {
            PLACEHOLDER: "Email",
            ERROR_MSG: "Email er <strong>påkrævet</strong>",
            INVALID: "ugyldig emailadresse",
            INVALID_EMAIL: "Email skal være en gyldig e-mailadresse",
          },
          BIRTHDAY: {
            PLACEHOLDER: "Fødselsdag",
            REQUIRED: "Fødselsdag er <strong>påkrævet</strong>",
            ERROR_MSG: "ugyldig dato",
            ERROR_MSG_REQUIRED: "Fødselsdag er <strong>påkrævet</strong>",
          },
          PAYMENT_STATUS: "Betalingsservice status",
          CREDIT_APPROVAL_DATE: {
            PLACEHOLDER: "Kreditgodkendelsesdag",
            ERROR_MSG: "ugyldig dato",
          },
          ADDRESS: {
            PLACEHOLDER: "Adresse",
            ERROR_MSG: "Adresse er <strong>påkrævet</strong>",
            MAX_LENGTH: "Adresse er skal være <strong> på 100 tegn.</strong>",
          },
          CITY: {
            PLACEHOLDER: "By",
            ERROR_MSG: "By er <strong>påkrævet</strong>",
            MAX_LENGTH: "By skal være <strong> på 100 tegn.</strong>",
          },
          POSTAL_CODE: {
            PLACEHOLDER: "Postnummer",
            ERROR_MSG: "Postnummer er <strong>ikke gyldigt</strong>",
            REQUIRED: "Postnummer er <strong>påkrævet</strong>",
            MAX_LENGTH: "Postnummer skal være <strong> på 100 tegn.</strong>",
          },
          ACCOUNT_MANAGER: {
            PLACEHOLDER: "Kundeansvarlig",
            ERROR_MSG: "Kundeansvarlig er <strong>påkrævet</strong>",
          },
          CUSTOMER_TYPE: {
            PLACEHOLDER: "Kundetype",
            ERROR_MSG: "Kundetype er <strong>påkrævet</strong>",
          },
          PAYMENT_TERM: {
            PLACEHOLDER: "Betalingsbetingelser",
            ERROR_MSG: "Betalingsbetingelser er <strong>påkrævet</strong>",
          },
          COMPANY_NAME: {
            PLACEHOLDER: "Virksomhedens navn",
            ERROR_MSG: "Virksomhedens navn er <strong>påkrævet</strong>",
          },
          PHONE: {
            PLACEHOLDER: "Telefon",
            ERROR_MSG: "Telefon er <strong>påkrævet</strong>",
          },
          ID_NUMBER: {
            PLACEHOLDER: "CPR/CVR",
            ERROR_MSG: "CPR/CVR er <strong>påkrævet</strong>",
            MAX_LENGTH: "CVR nummeret skal være <strong> på 10 tegn.</strong>",
          },
          BANK_CODE: {
            PLACEHOLDER: "Bank reg.nr.",
            ERROR_MSG: "Bank reg.nr. er <strong>påkrævet</strong>",
            MAX_LENGTH: "Bank reg.nr. skal være <strong> på 4 tegn.</strong>",
          },
          BANK_ACCOUNT_NUMBER: {
            PLACEHOLDER: "Bank kontonr.",
            ERROR_MSG: "Bank kontonr. er <strong>påkrævet</strong>",
            MAX_LENGTH: "Bank kontonr. skal være <strong> på 11 tegn.</strong>",
            PATTERN: "Bankkontonr. skal være <strong> et tal.</strong>",
          },
          RISK: {
            PLACEHOLDER: "Risikoklassificeringer",
          },
          EXTERNAL_CUSTOMER_NUMBER: {
            PLACEHOLDER: "Økonomisk nummer",
          },
          EAN_NUMBER: {
            PLACEHOLDER: "EAN nummer",
            LENGTH_ERROR: "EAN-nummer skal være på 13 cifre",
            ERROR_MSG: "EAN nummer er <strong>påkrævet</strong>",
          },
        },
      },
      DETAILS: {
        FILE_NAME: "Filnavn",
        DOCUMENT_TYPE: "Dokument Type",
        CREATED_AT: "Oprettet Kl",
        DOCUMENTS: "Dokumenter",
        RECENT_DOCUMENTS: "Nylige Dokumenter",
        CUSTOMER_INFO: "Kundeinfo",
        SUB_CUSTOMER: "Underkunde",
        CUSTOMER: "kunde",
        FINANCIAL_INFO: "Finansiel info",
        NAME: "Navn",
        CVR: "CVR",
        CONTACT_PERSON: "Kontaktperson",
        COMPANY_STATUS: "Virksomhedens status",
        EDIT_INFO: "Rediger kundeinfo",
        MONTHLY_INCOME: "Månedlig indkomst",
        MONTHLY_SPENDING: "Månedlige omkostninger",
        MONTHLY_BUDGET: "Månedligt budget",
        REAL_ESTATE: "Ejendom",
        CASH_HOLDING: "Kontantbeholdning",
        SECURITIES: "Værdipapirer",
        EDIT_FINANCIAL: "Rediger finansiel info",
        LATEST_CONTRACTS: "Seneste kontrakter",
        LATEST_INVOICES: "Seneste fakturaer",
        SEE_ALL_OFFERS: "Se alle tilbud",
        LATEST_OFFERS: "Seneste tilbud",
        PAYMENT_AGREEMENT: "Betalingsaftale",
        NOTIFICATIONS: "Notifikationer",
        TYPE: "Type",
        STATUS: "Status",
        BANK_REG: "Bank reg",
        BANK_ACC: "Bank konto",
        NEW_AGREEMENT: "Ny aftale",
        CANCEL_AGREEMENT: "Annuller aftale",
        GO_TO_CUSTOMER: "Gå til kunde",
        INCOME_DOCUMENTS_BUTTON: "Indkomstdokumenter",
        METRICS: {
          NAME: "Nøgletal",
          ACTIVE_CONTRACT: "Aktive kontrakter",
          TOTAL_CONTRACTS: "Alle kontrakter",
          TOTAL_BALANCE: "Ikke-betalte ydelser ekskl. moms",
          INVOICES_OVERDUE: "Forfaldne fakturaer",
        },
        AGREEMENT: {
          SUCCESS: "Betalingsaftale oprettet",
          FAILED: "Kunne ikke oprette betalingsaftale",
          CREATE: "Tilmeld betalingsservice",
          FAILED_CHECK_CUSTOMER: "Kunne ikke tjekke for langt betalende kunde",
          NO_CUSTOMER: "Ingen tilsvarende kunde i FarPay. kan ikke oprette en betalingsservice",
          NO_DATA: "Manglende data. Kan ikke oprette betalingsservice",
          EXISTING_AGREEMENT: "Eksisterende betalingsservice vil blive slettet, er du sikker på at fortsætte\n",
          CONFIRM: "Bekræftet",
          STATUS: {
            PENDING: "Afventer",
            OK: "Ok",
            NONE: "Ingen",
            ERROR: "Erre",
            CANCEL: "Annulleret",
          },
        },
      },
    },
    VEHICLE: {
      STATUS: {
        NONE: "Ingen",
        INVENTORY: "Lager",
        SOLD: "Solgt",
        ON_CONTRACT: "På Kontrakt",
      },
      FUEL_TYPES: {
        GASOLINE: "Benzin",
        DIESEL: "Diesel",
        ELECTRIC: "Elektrisk",
        HYBRID: "Hybrid",
      },
      ASSET_TYPES: {
        VEHICLE: "Køretøjer",
        LOAN: "Lån",
      },
      VIEW: {
        TITLE: "Køretøjer",
        HEAD_TITLE: "Køretøjer",
        NEW_BUTTON: "Nyt køretøj",
        NEW_BUTTON_TOOLTIP: "Opret nyt køretøj",
        EDIT_BUTTON: "Rediger køretøj",
        DELETE_BUTTON: "Slet køretøj",
        VEHICLE_ID: "Køretøj ID",
        IMPORT: "Importer køretøjer",
        NO_VEHICLE_FOUND: "Ingen køretøjer fundet",
        TABLE_COLUMNS: {
          MODEL: "Model",
          VEHICLE_ID: "KØRETØJ ID",
          TYPE: "Type",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
        },
        UPDATE_FROM_DBI: "Opdatere køretøj fra integration",
        UPDATE_VEHICLE: "Opdatere køretøj",
        UPDATE: "Opdatere",
        VIN_NUMBER_MISSING: "Vin nummer mangler!",
        CONFIRM_UPDATE: "Er du sikker på, at du vil opdatere køretøjets detaljer?",
      },
      ADD: {
        ADD_TITLE: "Opret ny køretøj",
        EDIT_TITLE: "Rediger køretøj",
        ADD_TYPE_TITLE: "Opret nyt køretøj",
        TAB1_TITLE: "Generel info",
        TAB2_TITLE: "Ekstraudstyr",
        TAB3_TITLE: "Andet",
        TAB4_TITLE: "Markedsværdi",
        TAB5_TITLE: "Kilometerstand",
        FIELDS: {
          MAKE: {
            PLACEHOLDER: "Mærke",
            ERROR_MSG: "Mærke er <strong>påkrævet</strong>",
          },
          MODEL: {
            PLACEHOLDER: "Model",
            ERROR_MSG: "Model er <strong>påkrævet</strong>",
          },
          TRIM: {
            PLACEHOLDER: "Udstyrsvariant",
            ERROR_MSG: "Udstyrsvariant er <strong>påkrævet</strong>",
          },
          STATUS: {
            PLACEHOLDER: "Status",
            ERROR_MSG: "Status er <strong>påkrævet</strong>",
          },
          YEAR: {
            PLACEHOLDER: "Køretøjets årgang",
            ERROR_MSG: "Årgang er <strong>påkrævet</strong>",
          },
          VIN_NUMBER: {
            PLACEHOLDER: "Stelnummer",
            ERROR_MSG: "Stelnummer er <strong>påkrævet</strong>",
            VALID_LENGTH: "Stelnummer skal være <strong>på 9 eller 17 tegn.</strong>",
          },
          NEW_PRICE_CENTS: {
            PLACEHOLDER: "Køretøjets nypris i DKK",
            ERROR_MSG: "Nypris er <strong>påkrævet</strong>",
          },
          VEHICLE_TYPE: {
            PLACEHOLDER: "Køretøjstype",
            ERROR_MSG: "Køretøjstype er <strong>påkrævet</strong>",
          },
          FUEL_TYPE: {
            PLACEHOLDER: "Brændstofstype",
            ERROR_MSG: "Brændstofstype er <strong>påkrævet</strong>",
          },
          CONDITION: {
            PLACEHOLDER: "Køretøjets stand",
            ERROR_MSG: "Stand er <strong>påkrævet</strong>",
          },
          FIRST_REGISTERATION_DATE: {
            PLACEHOLDER: "Første indregistreringsdato",
            ERROR_MSG: "Første indregistreringsdato er <strong>påkrævet</strong>",
          },
          FUEL_CONSUMPTION: {
            PLACEHOLDER: "Brændstofsforbrug i km/l",
            ERROR_MSG: "Brændstofsforbrug er <strong>påkrævet</strong>",
          },
          MILEAGE: {
            HAS_PHOTO_LABEL: "Upload billede af km-stand",
            PHOTO_LABEL: "billede af km-stand",
            TITLE: "Kilometerstand",
            PLACEHOLDER: "Kilometerstand på køretøjet i km",
            ERROR_MSG: "Kilometerstand er <strong>påkrævet</strong>",
            PHOTO_ERROR_MSG: "Billede af km-stand er <strong>påkrævet</strong>",
          },
          LATEST_MILEAGE: {
            PLACEHOLDER: "Seneste kilometerstand",
          },
          MARKET: {
            TITLE: "Markedsværdi",
            PLACEHOLDER: "Køretøjets markedsværdi i DKK ex. moms",
            ERROR_MSG: "Køretøjets markedsværdi er <strong>påkrævet</strong>",
          },
          ELECTRIC_CONSUMPTION: {
            PLACEHOLDER: "Elektrisk forbrug i wh/km",
            ERROR_MSG: "Elektrisk forbrug er <strong>påkrævet</strong>",
          },
          ELECTRIC_RANGE: {
            PLACEHOLDER: "Elektrisk rækkevidde i km",
            ERROR_MSG: "Elektrisk rækkevidde er <strong>påkrævet</strong>",
          },
          AIRBAGS: {
            PLACEHOLDER: "Antal airbags",
            ERROR_MSG: "Antal airbags er <strong>påkrævet</strong>",
          },
          CHILD_SEATS: {
            PLACEHOLDER: "Integr. børnesæder",
            ERROR_MSG: "Antal integrerede børnesæder er <strong>påkrævet</strong>",
          },
          SEAT_BELT_ALARMS: {
            PLACEHOLDER: "Selealarmer",
            ERROR_MSG: "Antal selealarmer er <strong>påkrævet</strong>",
          },
          IS_SPECIAL_USAGE: {
            PLACEHOLDER: "Særlig anvendelse",
          },
          IS_NCAP: {
            PLACEHOLDER: "NCAP-markering (min. 5 stjerner)",
          },
          IS_ABS: {
            PLACEHOLDER: "ABS-bremser",
          },
          FULE_NORM: {
            PLACEHOLDER: "Brændstofsmålenorm",
            ERROR_MSG: "Målenorm er <strong>påkrævet</strong>",
          },
          CO2_PER_KM: {
            PLACEHOLDER: "CO2-udslip i g/km",
            ERROR_MSG: "CO2-udslip er <strong>påkrævet</strong>",
          },
          CURB_WEIGHT: {
            PLACEHOLDER: "Egenvægt i kg",
            ERROR_MSG: "Egenvægt er <strong>påkrævet</strong>",
          },
          BATTERY_CAPACITY: {
            PLACEHOLDER: "Batterikapacitet i ampere timer",
            ERROR_MSG: "Batterikapacitet er <strong>påkrævet</strong>",
          },
          HORSE_POWER: {
            PLACEHOLDER: "Hestekræfter",
            ERROR_MSG: "Hestekræfter er <strong>påkrævet</strong>",
          },
          IS_USED_VAT: {
            PLACEHOLDER: "Brugtmoms",
          },
          NEXT_INSPECTION: {
            PLACEHOLDER: "Næste synsdato",
            ERROR_MSG: "Næste synsdato er <strong>påkrævet</strong>",
          },
          BOOKED_VALUE: {
            PLACEHOLDER: "Bogført værdi",
          },
          NOT_PROVIDED: {
            PLACEHOLDER: "Ikke angivet",
          },
        },
      },
      DETAILS: {
        SEE_ALL_CONTRACTS: "Se alle kontrakter",
        SEE_ALL_MILAGES: "Se alle kilometerdata",
        SEE_ALL_MARKETS: "Se alle gemte markedsværdier",
        SOLD: "Dette køretøj er allerede solgt",
        VALUATION_AND_REG_TAX: {
          DATE: "Dato",
          TITLE: "Handelspris og registreringsafgift",
          ID: "ID",
          MARKET_PRICE_CENTS: "Handelspris",
          REGISTRATION_TAX_CENTS: "Registreringsafgift",
          DOWNLOAD_DOCUMENT: "Download dokument",
          REFERENCE: "Reference",
          DOWNLOAD_SUCCESS: "Dokumentet er blevet downloadet",
          DOWNLOAD_ERROR: "Der skete en fejl under download af dokumentet",
          SEE_ALL_REG_TAXES: "Se alle registreringsafgift ",
        },
        LOCATION_OF_VEHICLE: "køretøjets placering",
      },
      OTHER: {
        IS_USED_VAT: "Brugtmoms",
      },
    },
    VEHICLE_MAKES: {
      VIEW: {
        TITLE: "Køretøjsmærker",
        HEAD_TITLE: "Administrer køretøjsmærker",
        NEW_BUTTON: "Nyt mærke",
        NEW_BUTTON_TOOLTIP: "Opret nyt mærke",
        EDIT_BUTTON: "Rediger rettighed",
        DELETE_BUTTON: "Slet rettighed",
        ADD_TYPE_BUTTON: "Opret køretøjstype",
        TABLE_COLUMNS: {
          NAME: "Navn",
          CATEGORY: "Kategori",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
        },
        DELETE_MODAL: {
          TITLE: "Slet rettighed",
          DESCRIPTION: "Er du sikker på, at du permanent vil slette denne rettighed?",
          WAIT_DESCRIPTION: "Rettighed bliver slettet...",
        },
      },
      ADD: {
        ADD_TITLE: "Opret nyt mærke",
        EDIT_TITLE: "Rediger mærke",
        ADD_TYPE_TITLE: "Opret ny køretøjstype",
        FIELDS: {
          NAME: {
            PLACEHOLDER: "Navn",
            ERROR_MSG: "Navn er <strong>påkrævet</strong>",
          },
          VEHICLE_TYPE: {
            PLACEHOLDER: "Køretøjstype",
            ERROR_MSG: "Køretøjstype er <strong>påkrævet</strong>",
          },
        },
      },
    },
    VEHICLE_MODELS: {
      ADD: {
        ADD_TITLE: "Opret nyt bilmodel",
        EDIT_TITLE: "Rediger bilmodel",
        FIELDS: {
          MODEL: {
            PLACEHOLDER: "Bilmodel",
            ERROR_MSG: "Bilmodel er <strong>påkrævet</strong>",
          },
          MAKE: {
            PLACEHOLDER: "Køretøjsmærker",
            ERROR_MSG: "Køretøjsmærker er <strong>påkrævet</strong>",
          },
        },
      },
      VIEW: {
        TITLE: "Køretøjsmodeller",
        NEW_BUTTON: "Nyt bilmodel",
        TABLE_COLUMNS: {
          MODEL: "Bilmodel",
          MAKE: "Køretøjsmærker",
          VEHICLE_TYPE: "Køretøjstype",
        },
      },
    },
    VEHICLE_TRACKERS: {
      VIEW: {
        TITLE: "GPS-tracker til køretøjer",
        TABLE_COLUMNS: {
          NAME: "Navn",
          VIN_NUMBER: "Vin nummer",
          IMEI_NUMBER: "IMEI nummer",
          TYPE: "Type",
          STATUS: "Status",
          EXPIRY: "Udløb kl",
          CREATED: "Oprettet kl",
          UPDATED: "Opdateret kl",
        },
      },
      STATUS: {
        ACTIVE: "aktiv",
        PAUSE: "pause",
        CANCELLED: "annulleret",
        OVERDUE: "forsinket",
        WILL_PAUSE: "vil holde pause",
        WILL_CANCEL: "vil annullere",
      },
    },
    VEHICLE_TRACKER_USERS: {
      ADD: {
        ADD_TITLE: "Opret ny trackerbruger",
        EDIT_TITLE: "Rediger trackerbruger",
        FIELDS: {
          NAME: {
            PLACEHOLDER: "Navn",
            ERROR_MSG: "Navn er <strong>påkrævet</strong>",
          },
          EMAIL: {
            PLACEHOLDER: "E-mail",
            ERROR_MSG: "E-mail er <strong>påkrævet</strong>",
            INVALID: "E-mail er <strong>ugyldig</strong>",
            SEND_SUCCESS: "Sendt e-mail med succes",
            SEND_FAILURE: "Kunne ikke sende e-mail",
          },
          TRACKERS: {
            PLACEHOLDER: "GPS trackere",
            ERROR_MSG: "GPS trackere er <strong>påkrævet</strong>",
          },
        },
      },
      VIEW: {
        TITLE: "GPS-tracker brugere",
        NEW_BUTTON: "Ny Tracker-bruger",
        TABLE_COLUMNS: {
          NAME: "Navn",
          EMAIL: "E-mail",
          TRACKERS: "Trackere",
          CREATED: "Oprettet kl",
          UPDATED: "Opdateret kl",
        },
      },
    },
    VEHICLE_MARKET_VALUE: {
      VIEW: {
        TITLE: "Køretøjets markedsværdi",
        HEAD_TITLE: "Køretøjets markedsværdi",
        NEW_BUTTON: "Ny markedsværdi",
        NEW_BUTTON_TOOLTIP: "Ny markedsværdi",
        TABLE_COLUMNS: {
          DATE: "Dato",
          VALUE_CENTS: "Markedsværdi",
          VALUATION_SOURCE: "Vurderingskilde",
          VALUATION_TYPE: "Vurderingstype",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
        },
      },
      ADD: {
        ADD_TITLE: "Opret ny markedsværdi",
        EDIT_TITLE: "Rediger markedsværdi",
        ADD_TYPE_TITLE: "Opret ny markedsværdi",
        FIELDS: {
          DATE: {
            PLACEHOLDER: "Dato",
            ERROR_MSG: "Dato er <strong>påkrævet</strong>",
          },
          VALUE_CENTS: {
            PLACEHOLDER: "Køretøjets markedsværdi i DKK ex. moms",
            ERROR_MSG: "Markedsværdi er <strong>påkrævet</strong>",
          },
          VALUATION_TYPE: {
            PLACEHOLDER: "vurderingstype",
            ERROR_MSG: "vurderingstype er <strong>påkrævet</strong>",
          },
          VALUATION_SOURCE: {
            PLACEHOLDER: "værdiansættelseskilde",
            ERROR_MSG: "værdiansættelseskilde er <strong>påkrævet</strong>",
          },
        },
      },
    },
    VEHICLE_TRANSACTIONS_EXPENSE: {
      VIEW: {
        TITLE: "Køretøjets vognkort",
        TABLE_COLUMNS: {
          DATE: "Dato",
          TRANSACTION_TEXT: "Tekst",
          VALUE_CENTS: "Tekst",
          ACTION: "Hent",
        },
      },
    },
    VEHICLE_MILEAGE: {
      VIEW: {
        TITLE: "Køretøjets kilometerstand",
        HEAD_TITLE: "Køretøjets kilometerstand",
        NEW_BUTTON: "Ny kilometerstand",
        NEW_BUTTON_TOOLTIP: "Ny kilometerstand",
        TABLE_COLUMNS: {
          DATE: "Dato",
          VALUE: "Kilometerstand",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
          IMAGE_URL: "Billede",
        },
      },
      ADD: {
        ADD_TITLE: "Opret ny kilometerstand",
        EDIT_TITLE: "Rediger kilometerstand",
        ADD_TYPE_TITLE: "Opret ny kilometerstand",
        FIELDS: {
          DATE: {
            PLACEHOLDER: "Dato",
            ERROR_MSG: "Dato er <strong>påkrævet</strong>",
          },
          VALUE: {
            PLACEHOLDER: "Kilometerstand",
            ERROR_MSG: "Kilometerstand er <strong>påkrævet</strong>",
          },
        },
      },
    },
    PRODUCTS: {
      VIEW: {
        TITLE: "Produkter",
        HEAD_TITLE: "Administrer produkter",
        NEW_BUTTON: "Nyt produkt",
        NEW_BUTTON_TOOLTIP: "Opret et nyt produkt",
        EDIT_BUTTON: "Rediger produkt",
        DELETE_BUTTON: "Slet produkt",
        TABLE_COLUMNS: {
          ID: "ID",
          NAME: "Navn",
          TYPE: "Type",
          PRICE_EXCEL_VAT_CENTS: "Salgspris ekskl. moms",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
        },
        FILTERS: {
          TYPE: "Type",
          PAYMENT_TYPE: "Betaling",
          TRANSACTION_TYPE: "Transaktion",
          AVAILABLE_IN_CALCULATOR: "Tilgængelig i leasingberegner",
          AFFECTING_CONTRACT_VALUE: "Påvirker kontraktværdien",
        },
      },
      ADD: {
        ADD_TITLE: "Opret nyt produkt",
        EDIT_TITLE: "Rediger produkt",
        TABS: {
          PRODUCT_INFO: "Produktinfo",
          INSURANCE_PRICES: "Forsikringspriser",
        },
        FIELDS: {
          NAME: {
            PLACEHOLDER: "Navn",
            ERROR_MSG: "Navn er <strong>påkrævet</strong>",
          },
          PRICE_EXCEL_VAT_CENTS: {
            PLACEHOLDER: "Salgspris ekskl. moms",
            ERROR_MSG: "Salgspris er <strong>påkrævet</strong>",
          },
          COST_PRICE_EXCEL_VAT_CENTS: {
            PLACEHOLDER: "Kostpris ekskl. moms",
            ERROR_MSG: "Kostpris er <strong>påkrævet</strong>",
          },
          EXTERNAL_PRODUCT_NUMBER: {
            PLACEHOLDER: "Eksternt varenr.",
            ERROR_MSG: "Eksternt varenr. er <strong>påkrævet</strong>",
          },
          EXTERNAL_PRODUCT_GROUP_NUMBER: {
            PLACEHOLDER: "Ekstern varegruppe nummer",
          },
          ACCOUNT_NUMBER: {
            PLACEHOLDER: "Bogføringskonto",
          },
          CONTRA_ACCOUNT_NUMBER: {
            PLACEHOLDER: "Modkonto",
          },
          VAT_CODE: {
            PLACEHOLDER: "Momskode",
          },
          CAR_EXPENSE: {
            PLACEHOLDER: "Skal udgiften vises på køretøjets vognkort?",
          },
          IS_PAYMENT_TYPE: {
            PLACEHOLDER: "Betalingstype",
            ERROR_MSG: "Betalingstype er <strong>påkrævet</strong>",
          },
          USED_IN_CONTRACT_ADD_TRANS: {
            PLACEHOLDER: "Bruges i kontrakttransaktioner",
          },
          USED_IN_VEHICLE_ADD_TRANS: {
            PLACEHOLDER: "Bruges i køretøjstransaktioner",
          },
          IS_TRANSACTION_TYPE: {
            PLACEHOLDER: "Transaktionstype",
            ERROR_MSG: "Transaktionstype er <strong>påkrævet</strong>",
          },
          CREATE_AUTOMATIC_TRANSACTIONS: {
            PLACEHOLDER: "Opret automatiske transaktioner ved fakturering",
            ERROR_MSG: "Automatiske Transaktioner : <strong>Dette felt er påkrævet</strong>",
          },
          IS_SUBJECT_TO_VAT: {
            PLACEHOLDER: "Momspligtigt produkt",
            ERROR_MSG: "Momspligtig er <strong>påkrævet</strong>",
          },
          IS_MONTHLY_PRODUCT: {
            PLACEHOLDER: "Månedligt produkt",
            ERROR_MSG: "Månedlig betaling er <strong>påkrævet</strong>",
          },
          IS_STANDARD: {
            PLACEHOLDER: "Standardprodukt ved tilbud/kontraktoprettelse",
          },
          HAS_DATE_INTERVAL: {
            PLACEHOLDER: "Har datointerval",
            ERROR_MSG: "Datointerval er <strong>påkrævet</strong>",
          },
          IS_AVAILABLE_IN_CALCULATOR: {
            PLACEHOLDER: "Kan tilføjes i leasingberegner",
            ERROR_MSG: "Tilgængelig i leasingberegner er <strong>påkrævet</strong>",
          },
          IS_VISIBLE_IN_CALCULATOR: {
            PLACEHOLDER: "Er synlig i leasingberegner",
            ERROR_MSG: "Synlig i leasingberegner er <strong>påkrævet</strong>",
          },
          IS_AFFECTING_CONTRACT_VALUE: {
            PLACEHOLDER: "Påvirker kontraktværdien",
            ERROR_MSG: "Påvirker kontraktværdien er <strong>påkrævet</strong>",
          },
        },
      },
    },
    INSURANCE_PRICES: {
      VIEW: {
        TITLE: "Forsikringspriser",
        HEAD_TITLE: "Forsikringspriser",
        NEW_BUTTON: "Ny forsikringspris",
        NEW_BUTTON_TOOLTIP: "Ny forsikringspris",
        TABLE_COLUMNS: {
          PRODUCT: "Produkter",
          VEHICLE_TYPE: "Køretøjstype",
          PRICE_EXCEL_VAT_CENTS: "Salgspris ekskl. moms",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
        },
      },
      ADD: {
        ADD_TITLE: "Opret ny forsikringspris",
        EDIT_TITLE: "Rediger forsikringspris",
        REMOVE_TITLE: "Fjern forsikringspris",
        SAVE_BUTTON_TITLE: "Gem forsikringspris",
        ADD_TYPE_TITLE: "Opret ny forsikringspris",
        FIELDS: {
          PRODUCT: {
            PLACEHOLDER: "Produkter",
            ERROR_MSG: "Produkter er <strong>påkrævet</strong>",
          },
          LEASING_TYPE: {
            PLACEHOLDER: "Leasing type",
            ERROR_MSG: "Leasing type er <strong>påkrævet</strong>",
          },
          VEHICLE_TYPE: {
            PLACEHOLDER: "Køretøjstype",
            ERROR_MSG: "Køretøjstype er <strong>påkrævet</strong>",
          },
          PRICE_EXCEL_VAT_CENTS: {
            PLACEHOLDER: "Salgspris ekskl. moms",
            ERROR_MSG: "Salgspris er <strong>påkrævet</strong>",
          },
          VEHICLE_FROM_PRICE_CENTS: {
            PLACEHOLDER: "Køretøjets nedre beløbsgrænse",
            ERROR_MSG: "Køretøjets nedre beløbsgrænse er <strong>påkrævet</strong>",
          },
          VEHICLE_TO_PRICE_CENTS: {
            PLACEHOLDER: "Køretøjets øvre beløbsgrænse",
            ERROR_MSG: "Køretøjets øvre beløbsgrænse er <strong>påkrævet</strong>",
          },
          COST_PRICE_EXCL_VAT_CENTS: {
            PLACEHOLDER: "Kostpris ekskl. moms",
            ERROR_MSG: "Kostpris er <strong>påkrævet</strong>",
          },
          PRICE_EXCL_VAT_CENTS: {
            PLACEHOLDER: "Salgspris ekskl. moms",
            ERROR_MSG: "Salgspris er <strong>påkrævet</strong>",
          },
          UPPER_AGE_LIMIT: {
            PLACEHOLDER: "øvre aldersgrænse",
          },
          UPPER_HORSE_POWER_LIMIT: {
            PLACEHOLDER: "øvre grænse for hestekræfter",
          },
        },
      },
    },
    ENTITY: {
      TYPES: {
        SPLIT_LEASING: "SplitLeasing",
        CORPORATE: "Erhverv",
        PRIVATE: "Privat",
      },
    },
    OFFERS: {
      VIEW: {
        TITLE: "Tilbud",
        HEAD_TITLE: "Administrer tilbud",
        NEW_BUTTON: "Nyt tilbud",
        NEW_BUTTON_TOOLTIP: "Opret et nyt tilbud",
        EDIT_BUTTON: "Rediger tilbud",
        DELETE_BUTTON: "Slet tilbud",
        TABLE_COLUMNS: {
          ID: "Tilbud",
          OFFER_NAME: "Navn",
          OFFER_TYPE: "Tilbudstype",
          MAKE: "Mærke",
          MODEL: "Model",
          VEHICLE_TRIM: "Variant",
          VEHICLE: "Køretøj",
          START_VALUE: "Startværdi",
          CUSTOMER: "Kunde",
          PERIOD: "Periode",
          STATUS: "Status",
          NOT_PROVIDED: "Ikke angivet",
        },
        DELETE_MODAL: {
          TITLE: "Slet tilbud",
          DESCRIPTION: "Er du sikker på, at du permanent vil slette tilbuddet?",
          WAIT_DESCRIPTION: "Tilbud bliver slettet...",
        },
      },
      DETAILS: {
        CUSTOM_FIELDS: "Tilpassede felter",
        OFFER_DETAILS: {
          TITLE: "Tilbudsdetaljer",
          OFFER_STATUS: "Tilbud status",
          OFFER_TYPE: "Tilbud type",
        },
        MONTHLY_PAYMENT: {
          TITLE: "Månedlig betaling",
        },
        FIRST_PAYMENT: {
          TITLE: "Første betaling",
        },
        CUSTOMER: {
          TITLE: "Kundeinfo",
        },
        OFFER_TASKS: {
          TITLE: "Tilbudsopgaver",
          AWARDED: "Tildelt",
          UNASSIGNED: "ikke tildelt",
          CREDIT: {
            TITLE: "Kreditgodkendelse",
            APPROVE: "Godkend",
            DECLINE: "Afvis",
            CREATE_CASE_FILE: "Opret sag",
            UPLOAD: "Upload",
          },
          INSURANCE: {
            TITLE: "Opret forsikring",
            BY_CUSTOMER: "Forsikret af kunde",
            INSURANCE_COMPANY: "Forsikringsselskab",
            CREATE_INSURANCE: {
              TITLE: "Opret forsikring",
              COPY_NECESSARY_INFO: "  Kopier nødvendige informationer til oprettelse af forsikring",
              SAVE: "Gem",
              CANCEL: "Annuller",
            },
          },
        },
      },
      ADD: {
        CREATE_CONTRACT: "Opret kontrakt",
        RENEW_CONTRACT: "Forny kontrakt",
        COPY_AS_OFFER: "Forny som tilbud",
        COPY_CONTRACT: "Kopier kontrakt",
        CREATE_OFFER: "Opret tilbud",
        REQUEST_CONTRACT: "Forespørg kontrakt",
        DOWNLOAD_OFFER: "Hent tilbud",
        ADD_TITLE: "Opret nyt tilbud",
        EDIT_TITLE: "Rediger tilbud",
        CHANGE_CUSTOMER: "Debitorskift",
      },
    },
    CONTRACTS: {
      SIGNING_STATUS: {
        SIGNING_PENDING: "Afventer underskrift",
        SIGNED: "Underskrevet",
        SIGNING_DECLINED: "Underskrift afvist",
        NOT_SIGNED: "Ikke underskrevet",
        SIGNING_PROCESSING: "Forarbejdning",
        RequireAction: "Kræv handling",
      },
      LEASING_TYPES: {
        FINANCIAL: "Finansiel",
        OPERATIONAL: "Operationel",
        AUTO_LOAN: "Billån",
        LOAN: "Lån",
        CREDIT_LINE: "Kredit",
        STANDSTILL: "Stilstand",
      },
      VIEW: {
        TITLE: "Kontrakter",
        HEAD_TITLE: "Administrer kontrakter",
        NEW_BUTTON: "Ny kontrakt",
        NEW_BUTTON_TOOLTIP: "Opret en ny kontrakt",
        EDIT_BUTTON: "Rediger kontrakt",
        DELETE_BUTTON: "Slet kontrakt",
        TABLE_COLUMNS: {
          ID: "Kontrakt",
          CONTRACT_NAME: "Navn",
          CONTRACT_TYPE: "Kontrakttype",
          CUSTOMER_MANAGER: "Kundeansvarlig",
          MAKE: "Mærke",
          MODEL: "Model",
          VEHICLE_TRIM: "Variant",
          END_DATE: "Slutdato",
          NO_OF_TASKS: "Åbne opgaver",
          VEHICLE: "Køretøj",
          CUSTOMER: "Kunder",
          PERIOD: "Periode",
          STATUS: "Status",
          CONTRACT_STATUS: "Kontrakt Status",
          DEALERSHIP_NAME: "Forhandler",
          DOWN_PAYMENT: "Udbetaling",
          SALVAGE_VALUE: "Restværdi",
          CONTRACT_VALUE: "Kontraktværdi",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
          LEASING_TYPE: "Leasing type",
          MORE: "Mere",
        },
        DELETE_MODAL: {
          TITLE: "Slet kontrakt",
          DESCRIPTION: "Er du sikker på, at du permanent vil slette kontrakten?",
          WAIT_DESCRIPTION: "Kontrakten bliver slettet...",
        },
      },
      STATUS: {
        DRAFT: "Kladde",
        PENDING: "Afventer",
        APPROVED: "godkendt",
        COMPLETED: "Afsluttet",
        CANCELED: "Aflyst",
        DECLINED: "Afvist",
        ACTIVE: "Aktiv",
        CLOSED: "Lukket",
      },
      ADD: {
        CONFIRMATION_BOX_TEXT: "Du har ændret i tilbuddet/kontrakten. Er du sikker på at du vil gemme ændringerne?",
        CONFIRMATION_TITLE: "Alert",
        ADD_TITLE: "Opret ny kontrakt",
        EDIT_TITLE: "Rediger kontrakt",
        NOTES: "Noter",
        TABS: {
          ASSET: "Aktiv",
          VEHICLE_VALUATION: {
            TITLE: "Registreringsafgift",
            TAB_NAME: "Registreringsafgift",
            UPLOAD_DOCUMENTS: "Upload dokumentation for handelspris og registreringsafgift",
            UPLOAD_DOCUMENT: "Upload billede af registreringsattest",
            INSERT_DOCUMENT: "Indsæt dit dokument her...",
          },
          CALCULATE_LEASING: "Leasingberegner",
          CHOOSE_CUSTOMER: {
            TITLE: "Kunde",
            CONTRACT_TYPE_WARNING: "Kontrakttypen er ændret",
          },
          SUMMARY: {
            TITLE: "Opsummering",
            CUSTOMER: "Kunde",
            ASSET: "Aktiv",
            LEASING_DETAILS: "Leasing detaljer",
            PAYMENTS: "Betalinger",
            SEND_OFFER: "Send tilbud",
            SEND_OFFER_PDF: "PDF’en bliver oprettet. Vent venligst",
          },
        },
        FIELDS: {
          CONTRACT_NAME: {
            PLACEHOLDER: "Kontrakt navn",
            ERROR_MSG: "Kontrakt navn er <strong>påkrævet</strong>",
          },
          ASSET_TYPE: {
            PLACEHOLDER: "Aktivtype",
            ERROR_MSG: "Aktivtype er <strong>påkrævet</strong>",
          },
          ASSET: {
            PLACEHOLDER: "Vælg eksisterende aktiv",
            ADD_NEW_TITLE: "Tilføj nyt aktiv",
            // CHOOSE_EXISTING_TITLE:'Choose existing asset',
            ERROR_MSG: "Aktiv er <strong>påkrævet</strong>",
            IMPORT: "Importer via integration",
          },
          CUSTOMER: {
            PLACEHOLDER: "Vælg eksisterende kunde",
            PRIVATE_CUSTOMER: "Privatkunde",
            COMPANY_CUSTOMER: "Erhvervskunde",
            CUSTOMER: "Kunde",
            ADD_NEW_TITLE: "Tilføj ny kunde",
            // CHOOSE_EXISTING_TITLE:'Choose existing asset',
            ERROR_MSG: "Kunde er <strong>påkrævet</strong>",
          },
          CUSTOMER_EMAIL: {
            PLACEHOLDER: "Email",
            ERROR_MSG: "Email er <strong>påkrævet</strong>",
          },
          CALCULATE_REG_TAX: {
            PLACEHOLDER: "Vil du beregne handelspris og registreringsafgift?",
          },
        },
        BUTTONS: {
          MOVE_TO_CALCULATE_LEASING: "Gå til leasingberegner",
        },
        ACTIONS: {
          CANCEL_CONTRACT: "Annuller kontrakt",
          UN_CANCEL_CONTRACT: "Genåben kontrakt",
        },
        CONFIRM_CANCEL: "Er du sikker på, at du vil annullere kontrakten?",
      },
      DETAILS: {
        LICENSE_PLATE_Types: {
          YELLOW: "Gule nummerplader",
          PARROT: "Papegøjeplader ",
          NO_PLATE: "Ingen nummerplader",
          WHITE: "Hvide nummerplader",
        },
        CONTRACT_DETAILS: {
          TITLE: "Kontrakt detaljer",
          EXTEND: "Forlæng Kontrakten",
          START_VALUE: "Startværdi",
          END_DATE: "Slutdato",
          ESTIMATED_PROFIT: "Estimeret profit",
          PBS_STATUS: "PBS status",
          MONTHLY_PAYMENT: "Månedlig ydelse",
          CONTRACT_STATUS: "Kontrakt status",
          INSURANCE_PAYMENT: "Forsikring per måned ",
          COMMISSION_CENTS: "Kommissions",
          PROFIT_ON_VEHICLE_CENTS: "Fortjeneste På Køretøjet",
          INSURANCE_COMPANY_NAME: "Forsikringsselskab",
          VEHICLE_PLATE_NUMBER: "Registreringsnummer",
          KILOMETER_LIMIT: "Forventet kørsel",
        },
        ASSET_DETAILS: {
          TITLE: "Aktiv detaljer",
          GO_TO_ASSET: "Gå til aktiv",
          LAST_INSPECTION_DATE: "Sidste synsdato",
          INSPECTION_RESULT: "Synsresultat",
          NEXT_INSPECTION_DATE: "Næste synsdato",
        },
        INVOICES: {
          TITLE: "Fakturaer",
          SEE_ALL_INVOICES: "Se alle fakturaer",
        },
        LATEST_PAYMENTS: {
          TITLE: "Seneste betalinger",
          SEE_ALL_PAYMENTS: "Se alle betalinger",
          CREATE_PAYMENT: "Opret betaling",
          CREATE_INVOICE: "Opret faktura",
        },
        MILEAGE: {
          TITLE: "Splitleasing kørselsdata",
          NEW_BUTTON: "Tilføj kørselsdata",
          NEW_BUTTON_TOOLTIP: "Tilføj manuel kørselsdata",
          SEE_ALL_MILAGES: "Se alt kørselsdata",
          ADD_MIEAGE: "Tilføj kørselsdata",
          EDIT_MILEAGE: "Rediger kørselsdata",
          GET_MIEAGE: "Hent kørselsdata",
          CREATE_ADJ_PAYMENTS: "Opret udligning",
          FROM_DATE: "Fra dato",
          TO_DATE: "Til dato",
          CUSTOMER: "Kunde",
          MILEAGE: "Kilometer",
          PRIVATE: "Privat",
          CORPORATE: "Erhverv",
          TOTAL: "Total",
          TOTAL_MILEAGE: "Total kilometer",
          PERCENT_SPLIT: "Faktisk split",
          BOOKED_INCL: "Oprettede momspligtige betalinger",
          BOOKED_EXCL: "Oprettede momsfrie betalinger",
          NOTE: "Der laves splitudligninger på henholdsvis momspligtige og momsfrie produkter. Udligning inkl. moms bliver beregnet på grundlag af betalinger på momspligtige produkter til og med den valgte måned. Udligning ekskl. moms bliver beregnet på grundlag af betalinger på momsfrie produkter. til og med den valgte måned.",
          ADJ_INCL: "Udligning inkl. moms",
          ADJ_EXCL: "Udligning ekskl. moms",
          DELETE_MODAL: {
            TITLE: "Slet kørselsdata",
            DESCRIPTION: "Er du sikker på, at du permanent vil slette kørselsdata?",
            WAIT_DESCRIPTION: "Kørselsdata bliver slettet...",
          },
          ADD: {
            FIELDS: {
              DATE: {
                PLACEHOLDER: "Dato",
                ERROR_MSG: "Dato er <strong>påkrævet</strong>",
              },
              ADJ_DATE: {
                PLACEHOLDER: "Vælg dato",
                ERROR_MSG: "Dato er <strong>påkrævet</strong>",
              },
              CUSTOMER: {
                PLACEHOLDER: "Kunde",
                ERROR_MSG: "Kunde er <strong>påkrævet</strong>",
              },
              MILEAGE: {
                PLACEHOLDER: "Kilometer",
                ERROR_MSG: "Kilometer er <strong>påkrævet</strong>",
              },
            },
          },
          INVALID_DATE: "Dato skal være efter eller lig med startdato",
        },
        CONTRACT_TASKS: {
          TITLE: "Kontrakt opgaver",
          AWARDED: "Tildelt",
          UNASSIGNED: "ikke tildelt",
          SIGNING_STATUS: "underskrifts status",
          CREDIT: {
            TITLE: "Kreditgodkendelse",
            APPROVE: "Godkend",
            DECLINE: "Afvis",
            CREATE_CASE_FILE: "Opret sag",
            UPLOAD: "Upload",
            ERROR: {
              CPR_UNAVAILABLE: "CPR-registret er utilgængeligt og kundens stamdata er derfor ikke blevet opdateret",
            },
          },
          INSURANCE: {
            TITLE: "Opret forsikring",
            BY_CUSTOMER: "Forsikret af kunde",
            INSURANCE_COMPANY: "Forsikringsselskab",
            CREATE_INSURANCE: {
              TITLE: "Opret forsikring",
              COPY_NECESSARY_INFO: "  Kopier nødvendige informationer til oprettelse af forsikring",
              SAVE: "Gem",
              CANCEL: "Annuller",
            },
          },
          END_AGREEMENT: {
            INPUT_LABEL: "Aftale Slutdato",
            CONFIRM_DIALOG: {
              BODY: "Det ser ud til at du har noget registreringsafgift tilgode. Vil du bogføre det nu?",
              HEADER: "Konfirmation",
            },
          },
          VEHICLE_REGISTRATION_DOCUMENT: {
            TITLE: "Registreringsdokument",
            FIELDS: {
              REGISTRATION_CODE_1: "Kode del 1",
              REGISTRATION_CODE_2: "Kode del 2",
            },
          },
          GPS_TRACKER: {
            MISSING_INFORMATION: "Nogle nødvendige oplysninger mangler",
            MISSING_TRACKER: "Køretøjet har ingen tracker tildelt.",
            EXISTING_TRACKER:
              "Der findes allerede en tracker ({{tracker_name}}). Er du sikker på, at du vil bestille en ny tracker?",
            ORDER_TRACKER: "Bestil GPS-tracker",
            SEND_AGREEMENT_NOTE: "Køretøj er overtaget fra et andet leasingselskab",
            APPROVE: "Marker som udført",
            ORDER_SUCCESS: "Bestilt tracker med succes",
            ORDER_ERROR: "Der opstod en fejl under bestilling af tracker",
            UPDATE_TRACKER: "Opdater informationer",
            UPDATE_SUCCESS: "Opdateret gps tracker med succes",
            UPDATE_ERROR: "Der opstod en fejl under opdatering af trackeren",
            TYPE_REQUIRED: "Type er påkrævet",
            TYPE: "Type",
          },
        },
        SIGNING_STATUS_TABLE: {
          CUSTOMER_NAME: "Kunde",
          EMAIL: "Email",
          STATUS: "Status",
          SIGNED_ON: "Signeret på",
          SEND_AGAIN: "Send igen",
          SUCCESS_MESSAGE: "Signaturlink er blevet sendt til",
          CONFIRMATION_TITLE: "Send e-mail igen",
          CONFIRMATION_BODY: "Er du sikker på Send signatur-e-mail igen?",
          ERROR: {
            REQUIRED: "Email er påkrævet",
            INVALID: "Ugyldig email",
          },
        },
        CREDIT_CUSTOMER_STATUS: {
          PENDING: "Verserende",
          INVITATION_SENT: "Email sendt",
          STARTED: "Startede",
          FINISHED: "Færdig",
          FAILED: "Mislykkedes",
          FINISHED_NO_FILE: "Udført uden fil",
        },
        TASKS: {
          TITLE: "Opgaver",
          CREATE_NEW_TASK: "Opret ny opgave",
        },
        TRANSACTIONS: {
          TITLE: "Transaktioner",
          SEE_ALL: "Se alle transaktioner",
          CREATE_FINANCIAL_VOUCHER: "Opret finansbilag",
          CREATE_SUPPLIER_INVOICE: "Opret leverandørfaktura",
          BOOK_TRANSACTION: "Bogfør",
          REVERSE_TRANSACTION: "Tilbagefør Transaktioner",
          DELETE_TRANSACTION: "Slet Transaktioner",
          BULK_ACTIONS: "Massehandlinger",
          BULK_IMPORTING: "Masseimport",
          DOWNLOAD_TEMPLATE: "Hent skabelon",
        },
        AMORTIZATION_SCHEDULE: {
          TITLE: "Amortiseringstabel",
          SEE_ALL: "Se hele amortiseringstabellen",
        },
        REG_TAX: {
          TITLE: "registreringsattest",
          TABLE_TITLE: "Kontraktfiler",
          DATE: "Dato",
          FILE_NAME: "Filnavn",
        },
      },
      CONTRACT_CANCELLED: {
        NO_TRANSACTION_PAYMENT: "Kontrakten er annulleret og du kan ikke oprette nye betalinger eller transaktioner.",
        NO_TASKS: "Kontrakten er annulleret, og du kan ikke udføre nogen opgave.",
      },
      CUSTOM_FIELDS: {
        TITLE: "Brugerdefinerede felter",
        ADD: "Tilføj brugerdefineret felt",
        FIELD_REQUIRED: "Feltet er påkrævet",
      },
    },
    TASKS_TABLE: {
      VIEW: {
        TITLE: "Opgaver",
        ASSIGN_TASK_BUTTON: "Tildel opgave",
        TABLE_COLUMNS: {
          NAME: "Navn",
          DUE_DATE: "Frist",
          CONTRACT: "Kontraktnummer",
          ASSIGNED_TO: "Tildelt",
          IS_COMPLETED: "Gennemført",
          FILE: "Fil",
        },
        COMPLETED: "Gennemført",
        NOT_COMPLETED: "Ikke gennemført",
        ALL: "Alle",
        TRUE: "Ja",
        FALSE: "Nej",
      },
    },
    VEHICLE_VALUATION: {
      ADD_COMPARABLE_VEHICLE: "Tilføj sammenligneligt køretøj",
      VEHICLE_PRICE: {
        TITLE: "Handelspris",
        SUB_TITLE: "Sammenlignelige køretøjer",
        ADD_ANOTHER_VEHICLE: "Tilføj nyt køretøj",
        REMOVE_VEHICLE: "Fjern køretøj",
        AVERAGE_TABLE: {
          AVERAGE: "Gennemsnit",
          ADVERTISEMENT_DEDUCTION: "Annoncefradrag",
          EXTRA_EQUIPMENT_ADJUSTMENT: "Justering for ekstraudstyr",
          ADD_EXTRA_EQUIPMENT: "Tilføj ekstraudstyr",
          MILEAGE_DEDUCTION: "Justering for kilometer",
          CONDITION_DEDUCTION: "Justering for stand",
          MARKETPRICE_AFTER_ADJUSTMMENTS: "Handelspris efter justeringer",
          REG_AFTER_ADJUSTMMENTS: "Registreringsafgift",
          EXTRA_EQUIPMENT_DEDUCTION: "Justering for ekstraudstyr",
          YEAR_ADJUSTMENT: "Justering for årgang",
          ADJ_ADV_PRICE: "Justeret annoncepris",
          MODEL_ADJUSTMENT: "Justering for model",
          ADJUSTED_PRICE: "Justeret pris",
        },
        MARKET_PRICE_DOCUMENTS: {
          TITLE: "Dokumentation",
          DROPZONE: {
            PLACEHOLDER: "Træk fil(er) hertil",
            ERROR_MSG: "Dokument er <strong>påkrævet</strong>",
          },
          DROPZONE_PLACEHOLDER: "Træk fil(er) hertil",
          FILE_UPLOADED: "Fil uploaded",
          ADD_ANOTHER_DOCUMENT: "Tilføj et dokument",
          REMOVE_DOCUMENT: "Fjern dokument",
          MARKET_PRICE_DOCUMENT: {
            PLACEHOLDER: "Vælg fil(er)",
            ERROR_MSG: "Dokumentation er <strong>påkrævet</strong>",
          },
          DELETE_MODAL: {
            TITLE: "Slet dokument",
            DESCRIPTION: "Er du sikker på at du permanent vil slette dette dokument?",
            WAIT_DESCRIPTION: "Dokument bliver slettet...",
          },
        },
        FIELDS: {
          WEB_PORTAL: {
            PLACEHOLDER: "Webportal",
            ERROR_MSG: "Webportal er <strong>påkrævet</strong>",
          },
          WEB_PORTAL_URL: {
            PLACEHOLDER: "Bilbasen URL",
            BUTTON: "Hente",
            ERROR_MSG: "Webportal URL er <strong>påkrævet</strong>",
          },
          VEHICLE_MAKE: {
            PLACEHOLDER: "Mærke",
            ERROR_MSG: "Mærke er <strong>påkrævet</strong>",
          },
          VEHICLE_MODEL: {
            PLACEHOLDER: "Model",
            ERROR_MSG: "Model er <strong>påkrævet</strong>",
          },
          FIRST_REGISTERATION_DATE: {
            PLACEHOLDER: "Første indregistreringsdato",
            ERROR_MSG: "Reg. dato er <strong>påkrævet</strong>",
          },
          MILEAGE: {
            PLACEHOLDER: "Kilometerstand",
            ERROR_MSG: "Kilometerstand er <strong>påkrævet</strong>",
          },
          ADVERTISED_PRICE: {
            PLACEHOLDER: "Annoncepris",
            ERROR_MSG: "Annoncepris er <strong>påkrævet</strong>",
          },
          ADJ_PRICE: {
            PLACEHOLDER: "Just. pris",
            ERROR_MSG: "Justeret pris er <strong>påkrævet</strong>",
          },
          ADJ_MILEAGE: {
            PLACEHOLDER: "Just. kilometer",
            ERROR_MSG: "Justeret kilometer er <strong>påkrævet</strong>",
          },
          EXTRA_EQUIPMENT: {
            TITLE: "Ekstraudstyr",
            ADD_ANOTHER_EQUIPMENT: "Tilføj yderligere udstyr",
            REMOVE_EQUIPMENT: "Fjern udstyr",
            NAME: {
              PLACEHOLDER: "Beskrivelse",
              ERROR_MSG: "Beskrivelse er <strong>påkrævet</strong>",
            },
            VALUE: {
              PLACEHOLDER: "Udstyrets nypris",
              ERROR_MSG: "Udstyrets nypris er <strong>påkrævet</strong>",
            },
          },
          NEW_PRICE: {
            PLACEHOLDER: "Køretøjets nypris",
            ERROR_MSG: "Køretøjets nypris er <strong>påkrævet</strong>",
          },
          NEXT_INSPECTION: "Næste synsdato",
        },
      },
      REGESTRATION_TAX: {
        TITLE: "Registreringsafgift",
        TABLES: {
          ADJUSTMENT_TO_THE_TEXABLE_VALUE: "Justering af den afgiftspligtige værdi",
          VEHICLE_MARKET_PRICE: "Handelspris",
          VEHICLE_NEW_PRICE: "Nypris",
          ELECTRIC_BATTERY_CAPACITY: "Batterikapacitet",
          TAXABLE_VALUE_AFTER_BATTERY_ADJUSTMENT: "Afgiftspligtig værdi efter justering",
          CALCULATION_OF_REGISTRATION_FEE: "Beregning af registreringsafgift",
          FIRST_SCALE: "1. skalaknæk",
          SECOND_SCALE: "2. skalaknæk",
          THIRD_SCALE: "3. skalaknæk",
          REG_TAX_BEFORE_ADJUSTMENT: "Afgift før justeringer",
          CO2_ADJUSTMENT: "Justeringer for CO2-udslip",
          REG_TAX_BEFORE_BOTTOM_DEDUCTION: "Afgift før bundfradrag",
          BOTTOM_DEDUCTION_ALL_CARS: "Bundfradrag",
          TAX_BEFORE_OTHER_ADJUSTMENT: "Afgift før andre Justeringer",
          VAN_ABOVE_3TONE_ADJUSTMENT: "Varebiler over 3 ton justering",
          TAX_BEFORE_PHASING_IN_DEDUCTION: "Afgift før indfasning",
          PHASING_IN_DEDUCTION: "Indfasningsfradrag",
          EMMISSION_IN_DEDUCTION: "Emissionsfradrag",
          FINAL_TAX: "Endelig registreringsafgift",

          AMOUNT: "Beløb",
          AIRBAGS: "Airbags",
          INTEGRATED_CHILDSEATS: "Integr. børnesæder",
          EURO_NCAP: "Euro NCAP",
          ABS_DEDUCTION: "ABS bremser",
          ELECTRIC_BATTERY_DEDUCTION: "Batterikapacitet",
          TOTAL_ADJUSTMENT: "Samlet justering",
          REGISTRATION_TAX_BEFORE_ADJUSTMENTS: "Afgift før justeringer",
          SCALE_85: "1. skalaknæk (85%)",
          SCALE_150: "2. skalaknæk (150%)",
          ADJUSTMENTS_TO_REGISTRATION_TAX: "Justeringer af afgiften",
          FUEL: "Brændstof",
          SEAT_BELTS: "Selealarmer",
          TOTAL_ADJUSTMENTS: "Samlet justering",
          TAXABLE_VALUE: "Afgiftspligtig værdi",
          REGESTRATION_TAX_AFTER_ADJ: "Afgift før elbil fradrag",
          ELECTRIC_FIXED_DEDUCTION: "Elbil/Pluginhybrid bundfradrag",
          ELECTRIC_VARIABLE_DEDUCTION: "Elbil/Pluginhybrid variabel",
          EXTRA_ELECTRIC_DEDUCTION: "Extra Electric Deductions",
          TOTAL_REGISTRATION_TAX: "Registreringsafgift",
        },
      },
    },
    LEASING_CALCULATOR: {
      FIELDS: {
        LEASING_TYPE: {
          PLACEHOLDER: "Leasing type",
          ERROR_MSG: "Leasing type er <strong>påkrævet</strong>",
        },
        CONTRACT_TYPE: {
          PLACEHOLDER: "Type",
          ERROR_MSG: "Type er <strong>påkrævet</strong>",
        },
        CONTRACT_PERIOD: {
          PLACEHOLDER: "Kontraktlængde i måneder",
          ERROR_MSGS: {
            REQUIRED: "Kontraktlængden er <strong>påkrævet</strong>",
            MAX: "Kontraktlængden kan ikke overstige 999",
          },
        },
        EXPECTED_START_DATE: {
          TITLE: "Startdato",
          PLACEHOLDER: "Hurtigst muligt",
          ERROR_MSG: "Startdato er <strong>påkrævet</strong>",
        },
        FIRST_REGISTERATION_DATE: {
          PLACEHOLDER: "Første indregistreringsdato",
          ERROR_MSG: "Første indregistreringsdato er <strong>påkrævet</strong>",
        },
        START_VALUE: {
          PLACEHOLDER: "Startværdi",
          ERROR_MSG: "Startværdi er <strong>påkrævet</strong>",
          PROVIDE_INVALID: "Angiv gyldig værdi",
        },
        VEHICLE_PRICE: {
          PLACEHOLDER: "Køretøjets købspris ekskl. moms",
          ERROR_MSG: "Købspris er <strong>påkrævet</strong>",
        },
        REGESTRATION_TAX: {
          PLACEHOLDER: "Registreringsafgift",
          ERROR_MSG: "Registreringsafgift er <strong>påkrævet</strong>",
        },
        LICENSE_PLATE_TYPE: {
          PLACEHOLDER: "Nummerpladetype",
          ERROR_MSG: "Nummerpladetype er <strong>påkrævet</strong>",
        },
        DOWN_PAYMENT_EX_VAT: {
          PLACEHOLDER: "Udbetaling ekskl. moms",
          ERROR_MSG: "Udbetaling er <strong>påkrævet</strong>",
        },
        DEPOSIT_EX_VAT: {
          PLACEHOLDER: "Depositum ekskl. moms",
          ERROR_MSG: "Depositum er <strong>påkrævet</strong>",
        },
        OPTIONAL_FIELDS: {
          TITLE: "Valgfrie felter",
          SALVAGE_VALUE: {
            PLACEHOLDER: "Restværdi",
            ERROR_MSG: "Restværdi er <strong>påkrævet</strong>",
          },
          COMMISSION: {
            PLACEHOLDER: "Provision ekskl. moms",
            ERROR_MSG: "Provision er <strong>påkrævet</strong>",
          },
          PROFIT_ON_VEHICLE: {
            PLACEHOLDER: "Avance på køretøj ekskl. moms",
            ERROR_MSG: "Avance er <strong>påkrævet</strong>",
          },
          PRIVATE_TAX: {
            PLACEHOLDER: "Beskatningsgrundlag",
            ERROR_MSG: "Beskatningsgrundlag er <strong>påkrævet</strong>",
          },
          LEASING_PAYMENT: {
            PLACEHOLDER: "Leasingydelse ekskl. moms",
            ERROR_MSG: "Leasingydelse er <strong>påkrævet</strong>",
          },
          KILOMETER_LIMIT: {
            PLACEHOLDER: "Forventet kørsel i perioden.",
            ERROR_MSG: "Forventet kørsel i perioden er <strong>påkrævet</strong>",
          },
          INTEREST_RATE: {
            PLACEHOLDER: "Årlig rente",
            ERROR_MSG: "Rentesats er <strong>påkrævet</strong>",
          },
          VARIABLE_INTEREST_RATE: {
            PLACEHOLDER: "Variabel rente",
            DESCRIPTION: "Bruge Variabel rente",
            ERROR_MSG: "Variabel rente er <strong>påkrævet</strong>",
          },
          MARKUP_INTEREST_RATE: {
            PLACEHOLDER: "Markup på referencerente",
            ERROR_MSG: "Markup på referencerente er <strong>påkrævet</strong>",
          },
          SPLIT_LEASING_PRIVATE_SHARE: {
            PLACEHOLDER: "Splitleasing privat andel",
            ERROR_MSG: "Privat andel er <strong>påkrævet</strong>",
          },
          SPLIT_LEASING_CORPORATE_SHARE: {
            PLACEHOLDER: "Splitleasing erhverv andel",
            ERROR_MSG: "Erhverv andel er <strong>påkrævet</strong>",
          },
        },
        ADD_ON_PRODUCTS: {
          TITLE: "Tillægsprodukter",
          ADD_ANOTHER_PRODUCT: {
            TITLE: "Tilføj et andet produkt",
            PLACEHOLDER: "Søg efter produkt",
            TOOLTIP: "Tilføj til produktliste",
          },
        },
      },
    },
    CALCULATION_RESULT: {
      FIRST_PAYMENT: "Førstegangsbetaling",
      MONTHLY_PAYMENT: "Månedlig betaling",
      PRIVATE: "Privat",
      CORPORATE: "Erhverv",
      TOTAL_BEFORE_VAT: "I alt før moms",
      VAT: "Moms",
      TOTAL_AFTER_VAT: "I alt efter moms",
      DETAILS: "DETALJER",
      HIDE_DETAILS: "SKJUL DETALJER",
      PROFIT: "Indtjening",
      AMOUNT: "Beløb",
      INTEREST_INCOME: "Renteindkomst",
      INTEREST_RATE: "Årlig rente",
      FUNDING_COST: "Finansieringomkostning",
      INCOME_FROM_ADDON_PRODUCTS: "Indkomst fra tillægsprodukter",
      COSTS_ON_ADDON_PRODUCTS: "Omkostning på tillægsprodukter",
      TOTAL_PROFIT: "Samlet indtjening",
      KEY_FIGURES: "Nøgletal",
      CORPORATE_KEY_FIGURES: "Erhvervsnøgletal",
      PROFIT_ON_VEHICLE: "Avance",
      CONTRACT_FEE: "Kontraktgebyr",
    },
    INVOICES: {
      VIEW: {
        SEE_ALL: "Se alle fakturaer",
        TITLE: "Fakturaer",
        HEAD_TITLE: "Administrer fakturaer",
        NEW_BUTTON: "Ny faktura",
        NEW_BUTTON_TOOLTIP: "Opret ny faktura",
        EDIT_BUTTON: "Rediger faktura",
        DELETE_BUTTON: "Slet faktura",
        TABLE_COLUMNS: {
          DATE: "Dato",
          INVOICE_NUMBER: "Fakturanr",
          CUSTOMER: "Kunde",
          VAT_CENTS: "Moms",
          TOTAL: "Total",
          NOTES: "Noter",
          AMOUNT_INCLUDE_VAT: "Beløb inkl. moms",
          AMOUNT_EXCLUDE_VAT: "Beløb ekskl. moms",
          DUEDATE: "Forfald",
          TEXT: "Tekst",
          PAYMENT_STATUS: "Betalt",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
        },
        DELETE_MODAL: {
          TITLE: "Slet faktura",
          DESCRIPTION: "Er du sikker på, at du permanent vil slette denne faktura?",
          WAIT_DESCRIPTION: "Faktura bliver slettet...",
        },
      },
      ADD: {
        ADD_TITLE: "Opret ny faktura",
        EDIT_TITLE: "Rediger faktura",
        PREPARE_TITLE: "Opret faktura",
        PREVIEW_TITLE: "Forhåndsvis faktura",
        SEND_BUTTON: "Send faktura",
        FIELDS: {
          DATE: {
            PLACEHOLDER: "Fakturadato",
            ERROR_MSG: "Fakturadato er <strong>påkrævet</strong>",
          },
          PAYMENTS_UP_TO: {
            PLACEHOLDER: "Betalinger til og med",
            ERROR_MSG: "Betalinger til og med er <strong>påkrævet</strong>",
          },
          NOTES_HEADLINE: {
            PLACEHOLDER: "Note overskrift",
            ERROR_MSG: "Note overskrift er <strong>påkrævet</strong>",
          },
          BULK_BY_MONTH: {
            PLACEHOLDER: "Inkluder betalinger til og med den valgte måned",
          },
          NOTES_TEXT: {
            PLACEHOLDER: "Note tekst",
            ERROR_MSG: "Note tekst er <strong>påkrævet</strong>",
          },
          PAYMENT_TERM: {
            PLACEHOLDER: "Betalingsbetingelser",
            ERROR_MSG: "Betalingsbetingelser er <strong>påkrævet</strong>",
          },
          PAYMENT_DUEDATE: {
            PLACEHOLDER: "Forfaldsdato",
            ERROR_MSG: "Forfaldsdato er <strong>påkrævet</strong>",
          },
          MONTH: {
            PLACEHOLDER: "Måned",
            ERROR_MSG: "Måned er <strong>påkrævet</strong>",
          },
        },
      },
    },
    PAYMENTS: {
      VIEW: {
        TITLE: "Betalinger",
        HEAD_TITLE: "Administrer betalinger",
        NEW_BUTTON: "Ny betaling",
        NEW_BUTTON_TOOLTIP: "Opret ny betaling",
        EDIT_BUTTON: "Rediger betalinger",
        DELETE_BUTTON: "Slet betalinger",
        REVERSE_PAYMENTS_BUTTON: "Tilbagefør betalingslinje",
        PAYMENT_REVERSED: "Betalingslinje tilbageført",
        ADD_BULK_INVOICES: "Foretag månedsfakturering",
        BOOKED: "Bogført",
        NOT_BOOKED: "Ikke Bogført",
        TABLE_COLUMNS: {
          DATE: "Dato",
          TYPE: "Type",
          TEXT: "Tekst",
          CUSTOMER_NAME: "Kunde",
          AMOUNT: "Beløb ekskl. moms",
          VAT_CENTS: "Moms",
          TOTAL_AMOUNT: "Total",
          VAT_DEDUCTION: "Momsfradrag",
          BOOKED_STATUS: "Bogført",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
          DATE_FROM: "Fra dato",
          DATE_TO: "Til dato",
          CONTRACT: "kontrakt",
          VEHICLE: "køretøj",
        },
        DELETE_MODAL: {
          TITLE: "Slet betaling",
          DESCRIPTION: "Er du sikker på, at du permanent vil slette denne betaling?",
          BULK_DESCRIPTION: "Er du sikker på, at du vil slette disse betalinger permanent?",
          WAIT_DESCRIPTION: "Betaling bliver slettet...",
        },
        REVERSE_MODAL: {
          TITLE: "Tilbagefør betaling",
          DESCRIPTION: "Er du sikker på, at du vil tilbageføre denne betaling?",
          BULK_DESCRIPTION: "Er du sikker på, at du vil tilbageføre disse betalinger?",
          WAIT_DESCRIPTION: "Betaling bliver tilbageført...",
          BUTTON: "Tilbagefør",
        },
        CONFIRM_MODAL: {
          TITLE: "Bekræft månedsfakturering",
          DESCRIPTION:
            "Bekræft venligst, at du vil oprette månedsfakturering.<br> Fakturaer vil blive oprettet og sendt til alle kunder, som har ikke-bogførte betalinger i den valgte periode.",
          WAIT_DESCRIPTION: "Månedsfakturering er bekræftet...",
        },
      },
      ADD: {
        ADD_TITLE: "Opret ny betaling",
        EDIT_TITLE: "Rediger betaling",
        SINGLE_PAYMENT: "enkeltbetaling",
        MONTHLY_PAYMENT: "månedlige betaling",
        FIELDS: {
          DATE: {
            PLACEHOLDER: "Dato",
            ERROR_MSG: "Dato er <strong>påkrævet</strong>",
          },
          START_DATE: {
            PLACEHOLDER: "Startdato",
            ERROR_MSG: "Startdato er <strong>påkrævet</strong>",
          },
          FROM: {
            PLACEHOLDER: "Fra",
            ERROR_MSG: "Fra er <strong>påkrævet</strong>",
          },
          TO: {
            PLACEHOLDER: "Til",
            ERROR_MSG: "Til er <strong>påkrævet</strong>",
          },
          PRODUCT: {
            PLACEHOLDER: "Produkt",
            ERROR_MSG: "Produkter er <strong>påkrævet</strong>",
          },
          PRICE_EXCEL_VAT_CENTS: {
            PLACEHOLDER: "Salgspris ekskl. moms",
            ERROR_MSG: "Salgspris er <strong>påkrævet</strong>",
          },
          VAT_DEDUCTION_CORPORATE_CENTS: {
            PLACEHOLDER: "Momsfradrag",
            ERROR_MSG: "Momsfradrag er <strong>påkrævet</strong>",
          },
          CUSTOMER: {
            PLACEHOLDER: "Kunde",
            ERROR_MSG: "Kunde er <strong>påkrævet</strong>",
          },
        },
      },
    },
    TASKS: {
      VIEW: {
        CREATE_FIRST_PAYMENT: {
          ADD: {
            MSG: "Der er ingen førstegangsbetalinger på denne kontrakt",
            ADD_TITLE: "Opret førstegangsbetaling",
            ACTIONS: {
              CREATE_FIRST_PAYMENT_BUTTON: "Opret førstegangsbetaling",
            },
            FIELDS: {
              DATE: {
                PLACEHOLDER: "Bogføringsdato",
                ERROR_MSG: "Bogføringsdato er <strong>påkrævet</strong>",
              },
              PAYMENT_TERM: {
                PLACEHOLDER: "Betalingsbetingelser",
                ERROR_MSG: "Betalingsbetingelser er <strong>påkrævet</strong>",
              },
              PAYMENT_DUEDATE: {
                PLACEHOLDER: "Forfaldsdato",
                ERROR_MSG: "Forfaldsdato er <strong>påkrævet</strong>",
              },
              NOTES_HEADLINE: {
                PLACEHOLDER: "Note overskrift",
                ERROR_MSG: "Note overskrift er <strong>påkrævet</strong>",
              },
              NOTES_TEXT: {
                PLACEHOLDER: "Note tekst",
                ERROR_MSG: "Note tekst er <strong>påkrævet</strong>",
              },
              CREATE_INVOICE: "Opret ikke faktura med det samme",
            },
            DELETE_DOCUMENT_MODAL: {
              TITLE: "Slet dokument",
              DESCRIPTION: "Er du sikker på at du permanent vil slette dette dokument?",
              WAIT_DESCRIPTION: "Dokument bliver slettet...",
            },
          },
        },
        CREATE_MONTHLY_PAYMENT: {
          ADD: {
            ADD_TITLE: "Opret månedlige betalinger",
            ACTIONS: {
              CREATE_MONTHLY_PAYMENT_BUTTON: "Opret månedlige betalinger",
            },
            FIELDS: {
              DATE: {
                PLACEHOLDER: "Kontraktens startdato",
                ERROR_MSG: "Startdato er <strong>påkrævet</strong>",
              },
              FINAL_REGISTRATION_TAX: {
                PLACEHOLDER: "Endelig registreringsafgift (total)",
                ERROR_MSG: "Endelig registreringsafgift er <strong>påkrævet</strong>",
              },
            },
            DELETE_DOCUMENT_MODAL: {
              TITLE: "Slet dokument",
              DESCRIPTION: "Er du sikker på at du permanent vil slette dette dokument?",
              WAIT_DESCRIPTION: "Dokument bliver slettet...",
            },
          },
        },
        BOOK_REGISTRATION_TAX: {
          NO_REGISTRATION_TAX: "Ingen registreringsafgift",
          END_TASK: "Der er ingen registreringsafgift  på denne kontrakt",
          MARK_TASK_DONE: "Afslut opgaven",
          ADD: {
            ADD_TITLE: "Bogfør registreringsafgift",
          },
        },
        SELL_VEHICLE: {
          ADD: {
            ADD_TITLE: "Sælg / flyt køretøj",
          },
          FIELDS: {
            DEPRECIATION_AMOUNT: "Angiv beløb i danske kroner som kontrakten skal afskrives med",
            VEHICLE_ACTION: "Sælg/Flyt køretøj",
            REVERSE_VEHICLE_ACTION: "fortryde Shell/Move",
            SELL_VEHICLE: "Sælg køretøj",
            MOVE_VEHICLE: "Flyt køretøj",
            SEND_INVOICE: "Opret faktura",
            SEND_PAYMENT_ADVICE: "Send betalingsadvis",
            CONFIRM_TASK_REVERSAL: "Vil du tilbageføre den sidste handling?",
            CONTRACTS: {
              PLACEHOLDER: "Kontrakter",
              ERROR_MSG: "Kontrakter er <strong>påkrævet</strong>",
            },
            SELLING_PRICE: {
              PLACEHOLDER: "Salgspris",
              ERROR_MSG: "Salgspris er <strong>påkrævet</strong>",
            },
            INVOICE_DATE: {
              PLACEHOLDER: "Fakturadato",
              ERROR_MSG: "Fakturadato er <strong>påkrævet</strong>",
            },
            PAYMENT_TERM: {
              PLACEHOLDER: "Betalingsbetingelser",
              ERROR_MSG: "Betalingsbetingelser er <strong>påkrævet</strong>",
            },
            CUSTOMER: {
              PLACEHOLDER: "Kunde",
              ERROR_MSG: "Kunde er <strong>påkrævet</strong>",
            },
          },
          NO_CONTRACTS_MSG:
            "Der findes ikke en kontrakt med dette køretøj endnu. Venligst opret en kontrakt med køretøjet for at flytte køretøjet til ny kontrakt.",
        },
        END_CONTRACT: {
          ADD: {
            ADD_TITLE: "Afslut kontrakt",
          },
          FIELDS: {
            SETTLEMENT_TYPE: "Slutopgørelse type",
            CONTRACT_VALUE: "Resterende kontraktværdi",
            INVOICE_CUSTOMER: "Fakturer kunde",
            TAKE_LOSS: "Bogfør transaktion",
          },
        },
        BOOK_VEHICLE: {
          ADD: {
            ADD_TITLE: "Bogfør køretøj",
            ON_CONTRACT_MSG: "Dette køretøj er i øjeblikket i brug på følgende kontrakt:",
          },
        },
        SEND_CONTRACT: {
          CONTRACT_FILES: "Kontraktfiler",
          ATTACHMENTS: "Kontraktbilag",
          ATTACHMENTS_LIST: "Liste over vedhæftede filer",
          GENERATED_ATTACHMENTS: "Genererede vedhæftede filer",
          UPLOADED_ATTACHMENTS: "Uploadede vedhæftede filer",
          CANCEL_CASE: "Dette vil annullere den tidligere sag",
          FIELDS: {
            CUSTOMER_NAME_LABEL: "Kundenavn",
            CUSTOMER_EMAIL_LABEL: "Kunde e-mail",
            COMPANY_NAME_LABEL: "Firmaets kontaktnavn",
            BANK_CODE_LABEL: "Bankkode",
            BANK_ACCOUNT_NUMBER_LABEL: "Bankkontonummer",
            ERROR_REQUIRED: "Indtast venligst en værdi",
          },
          ADD: {
            ADD_TITLE: "Send kontrakt til underskrift",
            ACTIONS: {
              SEND: "Send kontrakt til underskrift",
              GENERATE: "Generer kontrakt",
              GENERATE_USED_VAT: "Generer kontrakt brugt moms",
              UPLOAD: "Upload",
              UPLOAD_CONTRACT: "Upload kontraktfil",
              ENABLE_MANUAL_UPLOAD: "Aktivere manuel upload",
              DISABLE_MANUAL_UPLOAD: "Deaktiver manuel upload",
              SEND_GENERATED_CONTRACT: "Send genereret kontrakt",
              ACTIVATE_MANUAL_GENERATE: "Deaktiver manuel upload for at genaktivere",
              GENERATE_CONTRACT: "Generer kontrakt for at aktivere",
            },
            DELETE_DOCUMENT_MODAL: {
              TITLE: "Slet dokument",
              DESCRIPTION: "Er du sikker på at du permanent vil slette dette dokument?",
              WAIT_DESCRIPTION: "Dokument bliver slettet...",
            },
          },
          CONFIRM_CANCEL_MODAL: {
            TITLE: "Annullering af sagsmappe",
            BODY: "Du er ved at annullere/slette signeringen af kontrakten og åbne opgaven igen. Er du sikker?",
            ACTIONS: {
              CONFIRM: "Bekræft",
            },
          },
          CONFIRM_MODAL: {
            TITLE: "ved at sende kontrakten til underskrift, annullerer du den tidligere sag",
            ACTIONS: {
              CONFIRM: "confirm",
            },
          },
          ATTACH_DOCUMENTS: {
            FILES: "Filer",
            ADD: "tilføje vedhæftede filer",
            GENERATE: "Generer vedhæftet fil",
            MANDATORY_SIGN: "skal underskrives",
            ASSIGN_CONTRACT_TO: "Tildel kontraktunderskrivelse til",
          },
          GENERATE_ATTACHMENTS: {
            TITLE: "Generer kontraktskabeloner",
            SELECT_TEMPLATES: "Vælg skabeloner",
          },
        },
        END_CONTRACT_REMINDER: {
          ADD: {
            ADD_TITLE: "Afslut kontrakt påmindelse",
            ACTIONS: {
              COMPLETE_CONTRACT: "Marker som udført",
            },
          },
        },
      },
    },
    AMORTIZATIONS: {
      VIEW: {
        TITLE: "Kontrakt amortisering",
        HEAD_TITLE: "Administrer amortisering",
        NEW_BUTTON: "Ny contract Amortization",
        NEW_BUTTON_TOOLTIP: "Opret ny contract Amortization",
        EDIT_BUTTON: "Rediger contract Amortization",
        DELETE_BUTTON: "Slet contract Amortization",
        TABLE_COLUMNS: {
          DATE: "Dato",
          INTEREST_DAYS: "Rentedage",
          PAYMENT: "Betaling",
          INTEREST: "Rente",
          REPAYMENT: "Afdrag",
          BALANCE: "Balance",
          PROPORTIONATE_TAX_VALUE: "Rest reg-afgift",
        },
        DELETE_MODAL: {
          TITLE: "Slet contract Amortization",
          DESCRIPTION: "Er du sikker på, at du permanent vil slette denne finansieringsrente?",
          WAIT_DESCRIPTION: "finansieringsrente bliver slettet...",
        },
      },
      ADD: {
        ADD_TITLE: "Opret ny contract Amortization",
        EDIT_TITLE: "Rediger contract Amortization",
        FIELDS: {
          EFFECTIVE_FROM: {
            PLACEHOLDER: "Gældende fra",
            ERROR_MSG: "Gældende fra er <strong>påkrævet</strong>",
          },
          FUNDING_RATE: {
            PLACEHOLDER: "transaction",
            ERROR_MSG: "transaction er <strong>påkrævet</strong>",
          },
        },
      },
    },
    MONTHIO: {
      TITLE: "Brug Monthio/CVR-service",
      CONFIGURATION: {
        TITLE: "konfigurationer",
        PLACEHOLDER: "konfigurationer",
        ERROR_MSG: "Konfigurationer er <strong>påkrævet</strong>",
      },
      MESSAGES: {
        DELETE: "Sagsfilen blev slettet.",
        WARNING: "Du er ved at foretage nogle ændringer, eksisterende sagsmappe vil blive slettet.",
        NO_FILE: "Der er ikke noget regnskab offentligt tilgængeligt.",
        NO_RECENT_FILES: "Der blev ikke fundet nogen nyere filer til kunderne",
        DOCUMENT_EXPIRED: "Dokumentet er udløbet",
      },
    },
    CREDITRO: {
      TITLE: "Brug Creditro Service",
      CREATE_CASE: "Opret sag",
      RECREATE_CASE: "Genopret sag",
      MESSAGES: {
        WARNING:
          "Der findes allerede en sagsmappe! Dette vil slette den eksisterende sagsmappe og dens dokumenter og oprette en ny sag.",
        CREATE_SUCCESS: "Sagsfil blev oprettet.",
        CREATE_FAIL: "Der opstod en fejl under oprettelse af sagsfil, prøv venligst igen.",
        CONFIGURATION_ERROR: "Der opstod en fejl under hentning af konfiguration",
        NO_REAL_OWNERS: "Ingen rigtige ejere blev fundet",
        INVALID_ID_NUMBER: "Ugyldigt id-nummer. Kan ikke oprette sag.",
      },
      CASE: {
        CLIENT: "Klienttype",
        SERVICES: "Ydelse",
        MANAGER: "Klientansvarlig",
      },
      CUSTOMER: {
        REPRESENTITIVE: "Repræsentant",
        NAME: "Navn",
        EMAIL: "E-mail",
        STATUS: "Status",
        STATUSES: {
          EMAIL_UNVERIFIED: "E-mail ubekræftet",
          EMAIL_NOT_OPENED: "E-mail ikke åbnet",
          PENDING: "Afventer",
          DONE: "Færdig",
        },
        ROLES: {
          MAIN_CUSTOMER: "Hovedkunde",
          REAL_OWNER: "Rigtig ejer",
        },
      },
      STATUS: {
        EMAIL_NOT_OPENED_BY_CONTACT: "E-mail ikke åbnet",
        PENDING_RECONFIRMATION: "Afventer genbekræftelse",
        PENDING_PEOPLE: "Afventende personers",
        PENDING_PEPENDING_DOCUMENTS: "Afventende personers dokumenter",
        PENDING_INTERNAL_QUESTIONS: "Afventende interne spørgsmål",
        PENDING_ANSWERS: "Afventende svar",
        DONE: "Færdig",
      },
    },
    TRANSACTIONS: {
      VIEW: {
        TITLE: "Transaktioner",
        TRANSACTION_SET: "Transaktionssæt",
        HEAD_TITLE: "Administrer transaktioner",
        NEW_BUTTON: "Ny transaktion",
        NEW_BUTTON_TOOLTIP: "Opret ny transaktion",
        FINANCE_VOUCHERS_TITLE: "Finansbilag",
        FINANCE_TITLE: "Bogfør registreringsafgift",
        SUPPLIER_INVOICES_TITLE: "Leverandørfaktura",
        EDIT_BUTTON: "Rediger transaktioner",
        DELETE_BUTTON: "Slet transaktioner",
        TABLE_COLUMNS: {
          TRANSACTION_SET_ID: "Transaktions ID",
          DATE: "Dato",
          TEXT: "Tekst",
          TYPE: "Type",
          VAT_CODE: "Momskode",
          ACCOUNT_NUMBER: "Konto",
          AMOUNT: "Total",
          BOOKED_STATUS: "Bogført",
          BOOKED_VALUE: "Bogført værdi",
          CONTRACT_VALUE: "Kontraktværdi",
          CREATED: "Oprettet den",
          UPDATED: "Opdateret den",
        },
        DELETE_MODAL: {
          TITLE: "Slet transaktion",
          DESCRIPTION: "Er du sikker på, at du permanent vil slette denne transaktion?",
          WAIT_DESCRIPTION: "Transaktion bliver slettet...",
        },
        REVERSE_MODAL: {
          TITLE: "Tilbagefør transaktion",
          DESCRIPTION: "Er du sikker på, at du vil tilbageføre denne transaktion?",
          WAIT_DESCRIPTION: "Transaktion bliver tilbageført...",
        },
        BOOK_TRANSACTION_MODAL: {
          TITLE: "Bogfør transaktion",
          DESCRIPTION: "Er du sikker på, at du vil bogføre denne transaktion?",
          WAIT_DESCRIPTION: "Transaktion bliver bogført...",
        },
        IMPORT_TRANSACTION_MODAL: {
          TITLE: "Masseimport af transaktioner",
        },
        CONFIRM_MODAL: {
          TITLE: "Bekræft bogføring",
          DESCRIPTION: "Bekræft venligst, at du vil bogføre alle transaktioner i den valgte periode.",
          WAIT_DESCRIPTION: "Transaktion bliver bogført...",
        },
        ERROR: {
          MULTIPLE_TRANSACTIONS: "Handlingen kan ikke udføres. Check de markerede transaktioner og prøv igen.",
        },
      },
      ADD: {
        ADD_TITLE: "Opret ny transaktion",
        EDIT_TITLE: "Rediger transaktion",
        ACTIONS: {
          CREATE_TRANSACTION_BUTTON: "Opret transaktion",
          CREATE_INVOICE: "Vis faktura",
          SEND_PAYMENT_ADVICE: "Send betalingsadvis",
          IMPORT: "Importere",
          CREATE: "Opret transaktioner",
          UPLOAD_SHEET: "Upload fil",
          UPLOAD_ATTACHMENTS: "Upload vedhæftede filer",
        },
        FIELDS: {
          VEHICLE_PLATE_NUMBER: {
            PLACEHOLDER_NEW: "Registreringsnummer",
            PLACEHOLDER: "Registreringsnummer",
            ERROR_MSG: "Registreringsnummer er <strong>påkrævet</strong>",
          },
          DATE: {
            PLACEHOLDER_NEW: "Dato",
            PLACEHOLDER: "Bogføringsdato",
            ERROR_MSG: "Bogføringsdato er <strong>påkrævet</strong>",
          },
          PRODUCT: {
            PLACEHOLDER: "Produkter",
            ERROR_MSG: "Produkter er <strong>påkrævet</strong>",
          },
          TRANSACTION_TYPE: {
            PLACEHOLDER: "Transaktionstype",
            ERROR_MSG: "Transaktionsstype er <strong>påkrævet</strong>",
          },
          ACCOUNT_VAT_CODE: {
            PLACEHOLDER: "Moms",
            ERROR_MSG: "Momskode er <strong>påkrævet</strong>",
          },
          AMOUNT_CENTS: {
            PLACEHOLDER: "Beløb",
            PLACEHOLDER_FOR_CONTRACT: "Beløb ekskl. moms i valgt valuta",
            ERROR_MSG: "Totalbeløb er <strong>påkrævet</strong>",
          },
          CONTRA_ACCOUNT: {
            PLACEHOLDER: "Modkonto",
            ERROR_MSG: "Modkonto er <strong>påkrævet</strong>",
          },
          CREATE_MULTILINE: "Fordeling",
          VAT_CODE: {
            PLACEHOLDER: "Moms",
            ERROR_MSG: "Momskode er <strong>påkrævet</strong>",
          },
          AMOUNT: {
            PLACEHOLDER: "Totalbeløb",
            ERROR_MSG: "Totalbeløb er <strong>påkrævet</strong>",
          },
          ATTACHMENTS: {
            NAME: "Vedhæft bilag",
            PLACEHOLDER: "Træk fil(er) hertil..",
            ADD_ANOTHER_LINE: "Tilføj linje",
            REMOVE_THIS_LINE: "Fjern linje",
          },
          SUPPLIER: {
            PLACEHOLDER: "Leverandør",
            ERROR_MSG: "Leverandør er <strong>påkrævet</strong>",
          },
          SUPPLIER_INVOICE_NUMBER: {
            PLACEHOLDER: "Leverandør fakturanr.",
            ERROR_MSG: "Leverandør fakturanr. er <strong>påkrævet</strong>",
          },
          SUPPLIER_INVOICE_DUEDATE: {
            PLACEHOLDER: "Forfaldsdato",
            ERROR_MSG: "Forfaldsdato er <strong>påkrævet</strong>",
          },
          VEHICLE: {
            PLACEHOLDER: "Køretøjer",
            ERROR_MSG: "Køretøjer er <strong>påkrævet</strong>",
          },
          DOCUMENT: {
            PLACEHOLDER: "Dokument",
            ERROR_MSG: "Dokument er <strong>påkrævet</strong>",
          },
        },
        DELETE_DOCUMENT_MODAL: {
          TITLE: "Slet dokument",
          DESCRIPTION: "Er du sikker på at du permanent vil slette dette dokument?",
          WAIT_DESCRIPTION: "Dokument bliver slettet...",
        },
      },
    },
    GENERAL: {
      NO_RECORDS_FOUND: "Ingen resultater fundet",
      FILL_MISSING_FIELDS: "Udfyld de påkrævede felter.",
      VIEW: "Vis",
      CHANGE_CUSTOMER_TITLE: "Skift debitor pa kontrakt",
      SERVER_ERRORS_MSG_NEW: "Skift debitor pa kontrakt",
      SERVER_ERRORS_MSG: "Der opstod en intern server fejl. Forsøg venligst igen eller kontakt supporten.",
      ingen_kunder: "ingen kunder",
      SEARCH: "Søg",
      NONE: "ingen",
      DELETED: "Slettet",
      ERROR_DELETING: "Fejl ved sletning",
      ACTIVE: "Aktiv",
      CANCEL: "Annuller",
      REMOVE: "Fjern",
      IMPORT: "Importere",
      CLEAR: "Nulstil",
      DELETE: "Slet",
      REVERSE: "Tilbagefør",
      UPDATE: "Opdater",
      REVERSE_PAYMENTS_BUTTON: "Tilbagefør betalingslinje",
      EDIT: "Rediger",
      BACK: "Tilbage",
      ALL: "Alle",
      YES: "Ja",
      NO: "Nej",
      PERIOD: "Periode",
      RESET: "Nulstil",
      SAVE: "Gem",
      CONFIRM: "Bekræft",
      NEXT: "Næste",
      DETAILS: "Detaljer",
      SKIP_THIS_STEP: "Spring over",
      FILTERS: "Filter",
      TABLE_FILTERS: "Tabel filter",
      PLEASE_WAIT: "Vent venligst...",
      SOMETHING_WENT_WRONG: "Noget gik galt",
      SELECTED_RECORDS_COUNT: "Antal valgte: ",
      No_RECORDS_TO_SHOW: "Ingen data at vise",
      NO_RESULTS_FOUND: "Ingen resultater fundet",
      FORM_ERROR_MESSAGE: "Foretag nogle ændringer og prøv at indsende igen.",
      FORM_REQUIRED_FIELDS: "Felter markeret med * skal udfyldes.",
      DOWNLOAD: "Hent",
      PLEASE_ENTER: "Indtast venligst",
      REQUIRED: "påkrævet",
      LOADING: "Indlæser ...",
      MODEL_DELETE: " blev slettet",
      DELETE_NOTE: "slet note",
      DELETE_NOTE_MSG: "er du sikker på, at du vil slette denne note",
      DELETE_WAIT_MSG: "Vent venligst ...",
      REDIGER: "Rediger",
      REDSLETIGER: "Slet",
      PRICE: "pris",
      ITEM: "vare",
      ENABLE: "Aktivere/Deaktivere",
      REST_SINGING_TASK: "Annuller åben forsendelse",
      COMING_SOON: "Kommer snart",
      RESEND_EMAIL: "Send e-mail",
      DROPZONE: {
        DROP_FILES_HERE: "Træk fil(er) hertil",
      },
      DATATABLE: {
        ITEMS_PER_PAGE_LABEL: "Resultater per side:",
        NEXT_PAGE_LABEL: "Næste side",
        PREVIOUS_PAGE_LABEL: "Forrige side",
        FIRST_PAGE_LABEL: "Første side",
        LAST_PAGE_LABEL: "Sidste side",
        RANGE_PAGE_LABEL_1: "0 af {{length}}",
        RANGE_PAGE_LABEL_2: "{{startIndex}} - {{endIndex}} af {{length}}",
      },
      TABLE_COLUMNS: {
        NAME: "Navn",
        ID: "ID",
        ACTIONS: "Handlinger",
      },
      FIELDS: {
        PHOTO: "Foto",
      },
      VALIDATION: {
        REQUIRED_FIELD: "Påkrævet felt",
        EMAIL: "Ugyldig e-mail",
      },
      UNAUTHORIZED: {
        HOME: "Gå hjem",
      },
    },
    PATCH_NOTE: {
      TITLE: "Patch Noter",
      TYPE: "Type",
      TABLE: {
        FILTERS: {
          PATCH_NOTE: "Patch Noter",
          ANNOUNCEMENT: "Bekendtgørelse",
        },
        TITLE: "Titel",
        DATE: "Dato",
      },
    },
    FAQ: {
      TITLE: "FAQ",
    },
    SUB_CUSTOMERS: {
      TITLE: "Vedhæft underkunde",
      PRIVATE_CUSTOMER: "Privatkunde",
      COMPANY_CUSTOMER: "Erhvervskunde",
      PRIVATE_SUB_CUSTOMER: "Privat underkunde",
      COMPANY_SUB_CUSTOMER: "Erhvervs underkunde",
      TYPE: "Type",
      TYPE_REQUIRED: "Type er påkrævet",
      NAME_REQUIRED: "Name er påkrævet",
      ATTACH_SUB_CUSTOMER: "Tilføj Underkunde",
      SUB_CUSTOMER: "Underkunde",
      TYPES: {
        SECONDARY: "Sekundær kunde",
        GUARANTOR: "Kautionist",
        OWNER: " underskriver or signata",
      },
    },
  },
};
