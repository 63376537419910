<div class="kt-portlet cote-container">
  <div class="kt-portlet__head custom-note-h background__change pb-3">
    <div class="kt-portlet__head-label main-note-title">
      <h3 class="kt-portlet__head-title Letter__spacing">
        {{ "NOTES.VIEW.TITLE" | translate }}
      </h3>
      <span class="kt-portlet__head-icon kt-hide">
        <i class="la la-gear"></i>
      </span>
    </div>

    <div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
      <div class="kt-form__actions kt-form__actions--sm">
        <button
          class="btn btn-primary btn-edit"
          color="primary"
          mat-raised-button
          (click)="editNote(null)"
          *ngxPermissionsOnly="['create_notes', 'create_external_notes', 'create_pdf_notes']"
        >
          {{ "NOTES.ADD.ADD_TITLE" | translate }}
        </button>
      </div>
    </div>
  </div>
  <kt-portlet-body class="notes-list">
    <div class="timeline">
      <div class="container right" *ngFor="let note of notes">
        <div class="content">
          <div class="col-md-12" style="margin-top: -16px">
            <div class="d-flex justify-content-between align-content-start">
              <div class="title">
                <h6>{{ note?.category?.name }}</h6>
              </div>
              <div class="title">
                <mat-icon
                  *ngIf="showNotesActions(note)"
                  [matMenuTriggerFor]="menu"
                  inlineSVG="./assets/media/icons/dots.svg"
                >
                </mat-icon>
                <mat-menu #menu="matMenu" class="bg-primary">
                  <button mat-menu-item *ngxPermissionsOnly="['update_notes']" (click)="editNote(note)">
                    <div class="d-flex">
                      <div inlineSVG="./assets/media/icons/edit.svg" style="fill: white"></div>
                      <div class="ml-2 action-text" style="font-size: 14px">{{ "GENERAL.EDIT" | translate }}</div>
                    </div>
                  </button>
                  <button
                    mat-menu-item
                    class="d-flex"
                    *ngIf="note?.created_by_id == user?.id || user?.role?.name == 'super_admin'"
                    (click)="deleteProduct(note)"
                  >
                    <div inlineSVG="./assets/media/icons/trash.svg" style="fill: white"></div>
                    <div class="ml-2 action-text" style="font-size: 14px">{{ "GENERAL.DELETE" | translate }}</div>
                  </button>
                </mat-menu>
              </div>
            </div>
          </div>
          <span class="date ml-4" [innerHTML]="note?.created_at | dmyDate | safe"></span>
          <p class="customer-name" style="font-size: 11px">
            {{ note?.customer?.name ? note?.customer?.name : "ingen kunder" }}
          </p>
          <p class="note-text" [innerHTML]="note?.text"></p>
        </div>
      </div>
    </div>
  </kt-portlet-body>
</div>
